.deposit-reference-id-modal {
  border-radius: 1rem;
}

.deposit-reference-id-modal__content {
  padding: 3rem;
}

.deposit-reference-id-modal__header {
  border-bottom: 2px solid var(--tab__border-color--selected);
  padding: 1.6rem;
}

.deposit-reference-id-modal__header-content {
  display: flex;
  align-items: center;
}

.deposit-reference-id-modal__header-title {
  font-size: 2rem;
  color: var(--font__color-primary);
}

.deposit-reference-id-modal__footer {
  padding: 2.5rem 3rem;
  margin: 0;
}

.deposit-reference-id-modal__btn {
  width: 100%;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--success__bg-color);
  font-size: 1.4rem;
  height: 5rem;
  justify-content: center;
  padding: 2.8rem 0;
}

.deposit-reference-id-modal__title {
  font-size: 1.6rem;
  color: var(--font__color-primary);
  margin-bottom: 2.6rem;
}

.deposit-reference-id-modal__icon {
  margin-right: 1.5rem;
  width: 3.6rem;
  height: 3.6rem;
}

.deposit-reference-id-modal__reference-code-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 11.7rem;
  width: 35.1rem;
  border-radius: 6px;
  border: solid 1px #394154;
  padding: 2.1rem 5rem;
  margin-bottom: 1rem;
}

.deposit-reference-id-modal__reference-code-title {
  font-size: 1.2rem;
  color: var(--font__color-secondary-light);
  text-align: center;
  margin: 0;
  padding-bottom: 2rem;
}

.deposit-reference-id-modal__reference-code {
  font-size: 1.6rem;
  color: var(--font__color-primary);
  text-align: center;
  margin: 0;
}

.deposit-reference-id-modal__close-icon {
  color: var(--font__color-primary);
  padding: 1.4rem 1.1rem;
}
