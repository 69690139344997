.title-container {
  width: 100%;
  display:flex;
  flex-direction:row;
}

.col-left {
  width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.col-left h2 {
  font-size: 2.4rem;
  padding-left: 2.5rem;
  color: #fafafa !important;
}

.cp-container {
  display: flex;
  background-color: white;
  border-radius: 10px;
  min-height: 60rem;
}

.cp-container {
  display: flex;
  background-color: white;
  border-radius: 10px;
  min-height: 60rem;
}

.cp-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--secondary__bg-color);
  border-radius: 10px;
  min-height: 60rem;
  padding: 10rem;
}

.cp-success-img {
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.cp-success-header {
  text-align: center;
  margin-bottom: 2rem;
}

.cp-success-text {
  padding: 0 10rem;
  text-align: center;
  margin-bottom: 2rem;
}



div #mobilum-container {
  width: 100%;
  min-height: 50rem;
  margin: auto;
}

.col-right {
  width: 30%;
}

.card-img {
  position: relative;
  width: 100%;
  padding-right: 2.5rem;
}

.eotc-buy-sell-layout__col {
  margin-top: 50px;
}

.eotc-buy-sell-layout__col--lg {
  width: 100%;
}

.eotc-buy-sell-layout__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.eotc-buy-sell-layout__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.eotc-buy-sell-layout__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}
@media only screen and (max-width: 680px) {
  .cp-container {
    min-height: 70rem;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .cp-container {
    min-height: 70rem;
  }
  .eotc-buy-sell-layout__footer {
    margin-top: 3.4rem;
  }
}
