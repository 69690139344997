.fiat-sidepane-header {
  display: flex;
  margin-top: 1.6rem;
  margin-bottom: 4rem;
}

.fiat-sidepane-header__logo {
  display: inline-block;
  margin-right: 2.2rem;
}

.fiat-sidepane-header__title {
  font-size: 2.5rem;
  padding-top: 1rem;
  /* color: black; */
}
