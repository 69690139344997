.buy-sell-make-transaction {
  padding: 3.2rem 2.8rem 3.2rem 2.8rem;
  min-height: 100%;
}

.credit-card-make-transaction {
  padding: 3.2rem 2.8rem 3.2rem 2.8rem;
  min-height: 100%;
  width: 100%;
}

.buy-sell-make-transaction--alt-layout {
  padding: 3.2rem 0 0;
}

.buy-sell-make-transaction__title {
  padding: 0 0 2.8rem 3.3rem;
  letter-spacing: -0.5px;
}

.buy-sell-make-transaction__title--alt-layout {
  padding-bottom: 2rem;
}

.buy-sell-make-transaction__content {
  border: solid 0px var(--buy-sell-make-transaction__content-border-color);
  border-radius: 1rem;
}

.buy-sell-make-transaction__content--alt-layout {
  border: none;
}

@media only screen and (max-width: 48rem) {
  .buy-sell-make-transaction {
    padding: 3.7rem 0.6rem 3rem;
  }

  .buy-sell-make-transaction--layout-style-2 {
    padding: 0;
  }

  .buy-sell-make-transaction__title {
    padding: 0 0 1.6rem 1.2rem;
  }

  .buy-sell-make-transaction__title--layout-style-2 {
    display: none;
  }
}
