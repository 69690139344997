.order-book__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  border-left: none;
}

.trading-layout__tabs-container .order-book__container {
  border-left: none;
}

.orderbook__table-wrapper {
  display: block;
}

.orderbook__table-filler {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.orderbook__table-filler > :first-child {
  margin-top: auto;
}

.orderbook__table-header-price,
.orderbook__table-header-qty {
  cursor: pointer;
}

.orderbook__spread-row {
  padding: 0 0.5rem 0 1.15rem;
  flex-basis: var(--component__header-height);
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--component__color-primary);
  background: var(--orderbook__spread-bg-color);
  border-top: 1px solid var(--orderbook__spread-border-color);
  border-bottom: 1px solid var(--orderbook__spread-border-color);
}

.orderbook__center-button__image {
  margin-bottom: 0;
  height: 12px;
}

.orderbook__body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.orderbook__header {
  flex-basis: 3rem;
}

.orderbook__order-book-body {
  height: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.orderbook__spinner-container {
  position: relative;
}

.orderbook__spinner-container--sell {
  margin-bottom: 17rem;
}

.orderbook__spinner-container--buy {
  margin-top: 17rem;
}

.orderbook__vertical-scroller-body {
  flex-grow: 1;
  /* without static height property buys and sells blocks will have different heights, flex-grow: 1 without static height doesnt work*/
  /* it doesnt mean component will have 200px, no, it will take all space divided to 2 except spread block, which is expected behavior */
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.orderbook__vertical-scroller-body--firefox {
  overflow: auto;
  overflow-x: hidden;
}


.orderbook__vertical-scroller-body:last-child {
  justify-content: flex-start;
}

.orderbook__table-price {
  min-width: 8rem;
  padding: 0 1rem;
  cursor: pointer;
  text-align: right;
}

.orderbook__table-qty {
  flex: 1.2;
  padding: 0 0.3rem;
  min-width: 8rem;
  text-align: right;
  cursor: pointer;
}

.orderbook__table-my-size {
  padding: 0 0.3rem;
  flex: 1;
  text-align: right;
  min-width: 8rem;
}
.orderbook__cancel-btn {
  padding-right: 0.3rem;
  height: 100%;
  max-width: 2rem;
  min-width: 16px;
}

.orderbook__table-my-size--firefox {
  overflow-x: auto;
}

.orderbook__btn {
  padding: 0;
  border: none;
  height: 100%
}

.orderbook__image {
  height: 100%;
  width: 16px;
  margin: 0;
}
