/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@font-face {
  font-family: 'Now-Light';
  src: url("../fonts/Now-Light.eot");
  src: url("../fonts/Now-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Now-Light.woff2") format("woff2"), url("../fonts/Now-Light.woff") format("woff"), url("../fonts/Now-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Now-Medium';
  src: url("../fonts/Now-Medium.eot");
  src: url("../fonts/Now-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Now-Medium.woff2") format("woff2"), url("../fonts/Now-Medium.woff") format("woff"), url("../fonts/Now-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Now';
  src: url("../fonts/Now-Regular.eot");
  src: url("../fonts/Now-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Now-Regular.woff2") format("woff2"), url("../fonts/Now-Regular.woff") format("woff"), url("../fonts/Now-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Now-Black';
  src: url("../fonts/Now-Black.eot");
  src: url("../fonts/Now-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Now-Black.woff2") format("woff2"), url("../fonts/Now-Black.woff") format("woff"), url("../fonts/Now-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Now-Bold';
  src: url("../fonts/Now-Bold.eot");
  src: url("../fonts/Now-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Now-Bold.woff2") format("woff2"), url("../fonts/Now-Bold.woff") format("woff"), url("../fonts/Now-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

a {
  text-decoration: none !important;
}

body {
  background: #ffffff;
  color: #fff !important;
  font-size: 14px;
  font-family: "DM Sans" !important;
  /* font-family: "Now" !important; */
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

h1 {
  /* font-family: "Now-Bold"; */
  font-size: 72px;
  line-height: 1;
  color: #fff;
}

h2 {
  /* font-family: "Now-Bold"; */
  font-size: 72px;
  line-height: 1;
  color: #fff;
}

h3 {
  /* font-family: "Now-Bold"; */
  font-size: 42px;
  color: #fff;
}

h4 {
  /* font-family: "Now-Bold"; */
  font-size: 28px;
  color: #fff;
}

h5 {
  /* font-family: "Now-Bold"; */
  font-size: 25px;
  color: #fff;
}

p {
  font-size: 27px;
  line-height: 30px;
  color: #fff;
}

a {
  color: #000;
  /* font-family: "Now";  */
  font-family: "DM Sans";
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
}

a:hover {
  color: #000;
  text-decoration: none;
}

button:focus {
  outline: unset;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:not([href]):not():focus,
a:not([href]):not():hover,
a:not([href]):not([tabindex]) {
  color: #414042;
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

textarea {
  resize: none;
  width: 100% !important;
}

.red {
  color: red;
}

img {
  max-width: 100%;
}

.theme-btn {
  background: transparent linear-gradient(104deg, #16ef16bb 0%, #d8ff16b7 100%) 0% 0% no-repeat padding-box;
  border-radius: 43px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  /* font-family: "Now";  */
  font-family: "DM Sans";
  font-size: 28px;
  line-height: 37px;
  color: #FFFFFF;
  padding: 22px 80px;
  display: inline-block;
  text-align: center;
  letter-spacing: 0;
  font-weight: 700;
  border: 0;
}

.theme-btn.withoutgradient {
  background: rgba(0, 0, 0, 0.35);
  color: #FFFFFF;
}

.theme-btn.withoutgradient:hover {
  color: #FFFFFF;
}

.theme-btn.white-btn {
  background: rgba(255, 255, 255, 0.35);
  color: #fff;
}

.theme-btn.white-btn:hover {
  color: #fff;
}

.theme-btn.purple-btn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  color: #fff;
}

.theme-btn.purple-btn:hover {
  color: #fff;
}

.theme-btn:hover {
  color: #fff;
}

.theme-btn:disabled {
  opacity: 0.3;
}

.theme-btn.black-btn {
  background: #171E22 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #F5FBFF;
}

.theme-btn.small-btn {
  padding: 11px 32px;
  font-size: 16px;
  line-height: 19px;
  font-family: "Now-Medium";
}

.theme-btn.medium-btn {
  padding: 11px 28px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.gredientbg_one {
  background: transparent linear-gradient(237deg, #CC01FF 0%, #00FFDD 100%) 0% 0% no-repeat padding-box;
}

.gredientbg_two {
  background: transparent linear-gradient(240deg, #00FFDD 0%, #CC01FF 100%) 0% 0% no-repeat padding-box;
}

.gredientbg_three {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#CC01FF), to(#00FFDD)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #CC01FF 0%, #00FFDD 100%) 0% 0% no-repeat padding-box;
}

.gredientbg_four {
  background: transparent linear-gradient(237deg, #00FFDD 0%, #CC01FF 100%) 0% 0% no-repeat padding-box;
}

.gredientbg_five {
  background: transparent linear-gradient(123deg, #CC01FF 0%, #00FFDD 100%) 0% 0% no-repeat padding-box;
}

.gredientbg_six {
  background: transparent linear-gradient(234deg, #00FFDD 0%, #CC01FF 100%) 0% 0% no-repeat padding-box;
}

.login__screen__main {
  min-height: 100vh;
}

.sidebar__main {
  height: 100%;
  width: 90px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 57px;
  padding-bottom: 81px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.35);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  border-radius: 0 40px 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sidebar__main .brand__logo {
  margin-bottom: 90px;
  padding-left: 16px;
}

.sidebar__main .brand__logo a {
  min-height: 71px;
  display: block;
}

.sidebar__main .brand__logo a.fullicon {
  display: none;
  margin-left: 26px;
}

.sidebar__main .mainlinks__sidebar>a,
.sidebar__main .helplinks__sidebar>a {
  padding: 0px 30px;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  margin-bottom: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar__main .mainlinks__sidebar>a:last-of-type,
.sidebar__main .helplinks__sidebar>a:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}

.sidebar__main .mainlinks__sidebar>a img,
.sidebar__main .mainlinks__sidebar>a i,
.sidebar__main .helplinks__sidebar>a img,
.sidebar__main .helplinks__sidebar>a i {
  margin-right: 43px;
  font-size: 28px;
}

.sidebar__main.right__sidebar {
  right: 0;
  left: unset;
  border-radius: 40px 0px 0px 40px;
  padding-top: 20px;
}

.sidebar__main.right__sidebar .mainlinks__sidebar>a {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  padding: 0px 28px;
}

.sidebar__main.right__sidebar .mainlinks__sidebar img,
.sidebar__main.right__sidebar .mainlinks__sidebar i {
  margin-right: 0;
  margin-left: 43px;
}

.sidebar__main.right__sidebar .account__icon {
  text-align: right;
  margin-bottom: 131px;
  margin-right: 19px;
}

.sidebar__main.right__sidebar .account__icon a {
  color: #fff;
  font-size: 50px;
}

.sidebar__main.right__sidebar .account__icon a i {
  margin-left: 0;
}

.sidebar__main.opened {
  width: 315px;
  border-radius: 40px;
}

.sidebar__main a:hover {
  color: #f1f1f1;
}

main .sidebar__main {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.material-icons,
.icon-text {
  vertical-align: middle;
}

@media screen and (max-height: 450px) {
  .sidebar__main {
    padding-top: 15px;
  }

  .sidebar__main a {
    font-size: 18px;
  }
}

.login__screen__btns {
  padding-top: 48px;
  padding-right: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.login__screen__btns a {
  margin: 0 7px;
}

.login__screen__content h1 {
  text-align: left;
  font-size: 72px;
  line-height: 76px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0;
}

.login__form__main {
  background: #18191da6;
  border-radius: 17px;
  border: 1px solid #333333;
  opacity: 1;
  /* backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px); */
  padding: 40px 50px;
  text-align: center;
}

.signup-form .login__form__main {
  padding: 30px 50px;
}

.form-group {
  margin-bottom: 30px !important;
}

.form-control {
  background: var(--color-primary-light)!important;
  border-radius: 12px !important;
  opacity: 1 !important;
  backdrop-filter: blur(3px) !important;
  -webkit-backdrop-filter: blur(3px) !important;
  /* padding: 14px 17px 14px 22px !important; */
  font-size: 16px !important;
  line-height: 27px !important;
  letter-spacing: 0px !important;
  color: #FFFFFF;
  opacity: 1 !important;
  border: 0 !important;
  padding: .5rem 1.65rem !important;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;

}

.form-control::-ms-input-placeholder {
  color: #fff !important;

}

.form-control::placeholder {
  color: #fff !important;

}
.form-control:focus {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.form-control:-webkit-autofill {
  background: #363c4b !important;
}
.cant__login {
  margin-top: 30px;
}

.cant__login ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cant__login ul li a {
  /* font-size: 20px; */
  line-height: 27px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0 10px;
  font-weight: bold;
}

.cant__login ul li:not(:first-of-type) a {
  border-left: 1px solid #fff;
}

.login__form__center {
  min-height: calc(100vh - 129px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footerbg {
  background: #330046 0% 0% no-repeat padding-box;
  padding: 178px 0 143px;
  z-index: 4;
  position: relative;
}

.footerbg .upper__footer {
  margin-bottom: 234px;
}

.footer__logo__content {
  max-width: 487px;
}

.footer__logo__content p {
  margin-top: 117px;
  font-size: 33px;
  line-height: 64px;
  /* font-family: "Now-Bold"; */
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.footer__contact {
  max-width: 487px;
  text-align: right;
  margin-left: auto;
}

.footer__contact>a {
  letter-spacing: 0px;
  color: #00FFDD;
  font-size: 33px;
  line-height: 64px;
}

.footer__contact p {
  letter-spacing: 0px;
  color: #FFFFFF;
  font-size: 33px;
  line-height: 64px;
  margin-bottom: 45px;
  margin-top: 90px;
}

.footer__social__link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.footer__social__link ul li {
  margin: 0 9px;
}

.footer__social__link ul li a {
  font-size: 80px;
}

.footer__social__link ul li a .fab::before {
  background: linear-gradient(120deg, #00FFDD 0%, #CC01FF 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer__social__link ul li:last-of-type {
  margin-right: 0;
}

.footer__link ul li {
  margin-bottom: 36px;
}

.footer__link ul li:last-of-type {
  margin-bottom: 0;
}

.footer__link ul li a {
  letter-spacing: 0px;
  color: #FFFFFF;
  font-size: 27px;
  line-height: 36px;
}

.form-text {
  color: #fff !important;
  font-size: 12px;
  line-height: 16px;
  margin-top: 13px;
  padding-left: 35px;
  text-align: left;
}

.signup__underformtext {
  margin: 1rem;
}

.signup__underformtext p {
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
  color: #fff;
}

.banner__main {
  text-align: center;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.banner__main__inner {
  max-width: 1140px;
  margin: auto;
}

.banner__main__inner h1 {
  color: #fff;
  margin-top: 56px;
  margin-bottom: 30px;
}

.banner__main__inner p {
  max-width: 750px;
  margin: auto;
}

.banner__main__inner .banner__btns {
  margin-top: 56px;
}

.banner__main__inner .banner__btns .theme-btn {
  margin: 0 11px;
}

.theme__bg {
  background-color: #600084;
}

.cyrptolist {
  padding-top: 106px;
  padding-bottom: 185px;
}

.cryptolist__main {
  max-width: 795px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.cryptolist__main .coin__box {
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0px 20px 30px #00000099;
  box-shadow: 0px 20px 30px #00000099;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 18px;
}

.cryptolist__main .coin__box p {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 32px;
  /* font-family: "Now-Bold"; */
}

.cryptolist__main .coin__box span {
  color: #D0FFCB;
  font-size: 20px;
  line-height: 27px;
  /* font-family: "Now-Bold"; */
}

.cryptolist__main .coin__box span.negativelevel {
  color: #FFBEBE;
}

.cryptolist__main .coin__box .theme-btn {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
}

.cryptolist__main .coin__box .coin__title {
  min-width: 145px;
  text-align: right;
}

.cryptolist__main .coin__box .coin__short {
  min-width: 76px;
  text-align: left;
}

.cryptolist__main .coin__box .coin__price {
  min-width: 103px;
  text-align: right;
}

.cryptox__feature__main {
  padding-top: 119px;
  padding-bottom: 186px;
}

.cryptox__feature__main__inner {
  max-width: 1506px;
  margin: auto;
}

.cryptox__feature__main__inner .row .col-lg-6:last-of-type .cryptox__feature__box {
  margin-left: auto;
}

.cryptox__feature__box {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 47px 64px;
  max-width: 710px;
}

.cryptox__feature__box h3 {
  text-align: center;
  min-height: 71px;
}

.cryptox__feature__box p {
  text-align: center;
  font-size: 24px;
}

.cryptox__feature__box ul {
  margin-top: 60px;
  min-height: 156px;
}

.cryptox__feature__box ul li {
  letter-spacing: 0px;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 36px;
}

.cryptox__feature__box ul li i {
  margin-right: 17px;
  font-size: 17px;
  color: #00FFDD;
}

.cryptox__feature__box .cryptox__btn {
  text-align: center;
  margin-top: 60px;
}

.cryptoimage {
  margin-bottom: -55px;
  position: relative;
  z-index: 1;
  text-align: center;
  min-height: 612px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.powered__main {
  margin-top: 147px;
  margin-bottom: 90px;
}

.crypto__boxes {
  max-width: 1380px;
  margin: 0 auto;
}

.crypto__boxes__box {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 49px 66px;
  text-align: center;
  margin-bottom: 24px;
}

.crypto__boxes__box h3 {
  margin-bottom: 36px;
}

.crypto__boxes__box p {
  margin-bottom: 0;
  min-height: 90px;
}

.process__use {
  padding-top: 115px;
  -webkit-box-shadow: 0px -15px 40px #00000085;
  box-shadow: 0px -15px 40px #00000085;
  border-radius: 50px 50px 0 0;
  margin-top: -50px;
}

.process__use__inner {
  max-width: 965px;
  margin: auto;
  text-align: center;
  padding-bottom: 65px;
}

.process__use__inner .ceo__text {
  margin-top: 50px;
}

.process__use__inner .ceo__text h5 {
  font-size: 27px;
}

.how__it__work {
  -webkit-box-shadow: 0px -28px 30px #00000052;
  box-shadow: 0px -28px 30px #00000052;
  border-radius: 74px;
  padding: 42px 0;
}

.how__it__work__inner {
  max-width: 1423px;
  margin: auto;
}

.howitwork__text p {
  margin-bottom: 0;
  max-width: 570px;
}

.howwork__box {
  background: rgba(255, 255, 255, 0.35);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 13px;
  padding: 20px 35px;
}

.howwork__box .howwork__img {
  min-width: 254px;
  text-align: center;
  margin-right: 5px;
}

.howwork__box p {
  margin-bottom: 0;
}

.seen__on__media {
  padding-top: 126px;
  padding-bottom: 127px;
}

.seen__on__media__inner {
  max-width: 1610px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.seen__on__media__header {
  text-align: right;
  margin-bottom: 112px;
  padding-right: 120px;
}

.seen__on__media__box {
  background: rgba(0, 0, 0, 0.35);
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 46px 41px;
  border-radius: 60px;
  text-align: center;
}

.seen__on__media__box .seen__media__main {
  position: relative;

}

.seen__on__media__box .seen__media__main img {
  /* clip-path:ellipse(80% 80% at 90% 90%); */
  border-radius: 20px;
}

.seen__media__images img {
  border-radius: 20px;

}

/* .seen__on__media__box .seen__media__main img {
  clip-path:ellipse(50% 50% at 50% 50%);
} */
.seen__on__media__box .seen__title {
  margin-top: 47px;
  margin-bottom: 20px;
}

.seen__on__media__box .seen__title h4 {
  font-size: 25px;
  min-height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.img__short__action {
  position: absolute;
  top: 31px;
  left: 15px;
}

.img__short__action a {
  background: rgba(0, 0, 0, 0.15);
  opacity: 1;
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  width: 45px;
  height: 45px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}

.media__cat {
  background: rgba(255, 255, 255, 0.35);
  -webkit-box-shadow: 0px 10px 6px #00000029;
  box-shadow: 0px 10px 6px #00000029;
  border-radius: 34px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 15px 25px;
  display: inline-block;
  min-width: 192px;
  position: absolute;
  right: 0;
  bottom: -30px;
}

.media__cat p {
  margin-bottom: 0;
}

.seen__media__images ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.seen__media__images ul li {
  margin: 0 4.5px;
  position: relative;
}

.seen__media__images ul li:last-of-type {
  margin-right: 0;
}

.seen__media__images ul li:first-of-type {
  margin-left: 0;
}

.seen__media__images ul li span {
  font-size: 16px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.seen__readmore {
  text-align: right;
  padding-top: 35px;
}

.seen__readmore a {
  letter-spacing: 0px;
  color: #00FFDD;
  opacity: 1;
  font-size: 27px;
  line-height: 36px;
}

.get__started__boxes {
  padding-top: 207px;
  padding-bottom: 243px;
}

.get__started__boxes__inner {
  max-width: 1423px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.get__started__boxes__box {
  background: rgba(255, 255, 255, 0.35);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 45px 36px;
  margin-bottom: 83px;
}

.get__started__boxes__box:last-of-type {
  margin-bottom: 0;
}

.get__started__boxes__box .get__started__btn {
  text-align: right;
}

.get__started__boxes__box .get__started__btn .theme-btn {
  display: inline-block;
  padding: 23px 41px;
}

.get__started__boxes__box h3 {
  margin-bottom: 30px;
}

.get__started__boxes__box P {
  max-width: 571px;
}

.get__started__boxes__box.get__started__downloadmobile {
  margin-top: 250px;
}

.get__started__boxes__box.get__started__downloadmobile .get__started__content {
  text-align: right;
  position: relative;
}

.get__started__boxes__box.get__started__downloadmobile .get__started__content p {
  margin-left: auto;
}

.get__started__boxes__box.get__started__downloadmobile .get__started__content .theme-btn {
  margin-bottom: 21px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.get__started__boxes__box.get__started__downloadmobile .get__started__content .theme-btn span {
  margin-right: 25px;
}

.get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps {
  position: absolute;
  left: -48px;
  bottom: -120px;
}

.howwork__boxes {
  position: relative;
  z-index: 1;
}

.circle__object {
  position: absolute;
  /* display: none; */
}

.big__right__circle {
  right: -50px;
  top: 0;
  z-index: 0;
  max-width: 700px;
}

.big__left__circle {
  left: -50px;
  top: 15%;
  z-index: 0;
  max-width: 700px;
}

.right__circle {
  right: -50px;
  top: 48.5%;
  z-index: 0;
}

.left__circle {
  left: -50px;
  top: 58%;
  z-index: 0;
}

.right__one__circle {
  right: -50px;
  top: 66%;
  z-index: 0;
}

.left__one__circle {
  left: -50px;
  top: 76.5%;
  z-index: 0;
}

.mobile__screen__circles .big__right__circle {
  top: 4%;
}

.mobile__screen__circles .big__left__circle {
  top: 27%;
}

.mobile__screen__circles .big__right__one__circle {
  right: -50px;
  top: 49%;
  z-index: 0;
  max-width: 700px;
}

.cryptocurrent__header {
  text-align: center;
  position: fixed;
  top: 48px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9;
}

.cryptocurrent__header .nav-pills {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.34);
  border-radius: 35px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.cryptocurrent__header .nav-pills .nav-item .nav-link {
  /* font-family: "Now-Bold"; */
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 18px 26px;
  border-radius: 35px;
}

.cryptocurrent__header .nav-pills .nav-item .nav-link.active {
  background: rgba(0, 0, 0, 0.35);
  opacity: 1;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 35px;
}

.mobile__app__banner__center {
  padding-top: 442px;
  position: relative;
  z-index: 1;
}

.mobile__app__banner {
  width: 100%;
}

.mobile__app__banner__inner {
  max-width: 1120px;
  margin: auto;
}

.mobile__app__banner__box {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 63px 66px 51px;
  position: relative;
}

.mobile__app__banner__box__text {
  max-width: 570px;
}

.mobile__app__banner__box__img {
  position: absolute;
  top: -185px;
  right: 25px;
}

.mobile__app__banner__btns .theme-btn {
  padding: 19px 30px;
  margin: 0 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile__app__banner__btns .theme-btn img {
  margin-right: 20px;
}

.mobile__app__banner__btns .theme-btn:last-of-type {
  margin-right: 0;
}

.mobile__app__banner__btns .theme-btn:first-of-type {
  margin-left: 0;
}

.mobile__feature__wrapper {
  max-width: 1120px;
  margin: auto;
}

.app__features {
  padding-top: 303px;
  padding-bottom: 64px;
  position: relative;
  z-index: 1;
}

.app__features__header {
  text-align: center;
  margin-bottom: 118px;
}

.app__features__header h3 {
  margin-bottom: 36px;
}

.app__features__header p {
  max-width: 680px;
  margin: auto;
}

.app__features__boxes__box {
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 15px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 27px;
}

.app__features__boxes__box__icon {
  width: 112px;
  height: 112px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 25px;
}

.app__features__boxes__box__title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.app__features__boxes__box__title h4 {
  margin-bottom: 13px;
}

.app__features__boxes__box__title p {
  font-size: 20px;
  margin-bottom: 0;
}

.app__feature__mobile {
  margin-top: 30px;
  text-align: center;
}

.app__feature__steps {
  padding-top: 37px;
  padding-bottom: 272px;
}

.app__feature__steps__inner {
  max-width: 786px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.app__feature__steps__box {
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 26px 37px 64px;
  position: relative;
  margin: 266px 0;
}

.app__feature__steps__box__text {
  max-width: 403px;
}

.app__feature__steps__box__text h4 {
  margin-bottom: 23px;
}

.app__feature__steps__box__text p {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
}

.app__feature__steps__box__img {
  position: absolute;
  top: -185px;
  right: 0;
}

.theme__list {
  margin-bottom: 0;
}

.theme__list li {
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.theme__list li span {
  margin-right: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  color: #00FFDD;
  border: 1px solid #00FFDD;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 11px;
}

.theme__list li div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.app__feature__steps__inner .app__feature__steps__box:nth-child(even) .app__feature__steps__box__text {
  margin-left: auto;
}

.app__feature__steps__inner .app__feature__steps__box:nth-child(even) .app__feature__steps__box__img {
  left: 0;
  right: unset;
}

.app__feature__steps__appsc {
  text-align: center;
}

.app__feature__steps__appsc h3 {
  margin-bottom: 35px;
}

.app__feature__steps__appsc ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app__feature__steps__appsc ul li {
  margin: 0 20px;
}

.app__feature__steps__appsc ul li:first-of-type {
  margin-left: 0;
}

.app__feature__steps__appsc ul li:last-of-type {
  margin-right: 0;
}

.app__feature__steps__appsc ul li .theme-btn {
  padding: 19px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.app__feature__steps__appsc ul li .theme-btn img {
  margin-right: 20px;
}

.cryptoscdec__header {
  padding-top: 221px;
  padding-bottom: 72px;
}

.cryptoscdec__header .nav-pills {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cryptoscdec__header .nav-pills .nav-item {
  margin: 0 8px;
}

.cryptoscdec__header .nav-pills .nav-item .nav-link {
  background: rgba(0, 0, 0, 0.35);
  opacity: 1;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  color: #fff;
  font-size: 21px;
  line-height: 28px;
  /* font-family: "Now-Bold"; */
  padding: 18px 40px;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.cryptoscdec__header .nav-pills .nav-item .nav-link.active,
.cryptoscdec__header .nav-pills .nav-item .nav-link:hover {
  background: linear-gradient(104deg, rgba(204, 1, 255, 0.35) 0%, rgba(0, 255, 221, 0.35) 100%) 0% 0% no-repeat padding-box;
}

.digital__assets__heading {
  text-align: center;
}

.digital__assets__cards {
  margin-top: 82px;
  padding-bottom: 87px;
}

.digital__assets__cards__inner {
  max-width: 1234px;
  margin: auto;
}

.digital__assets__cards__card {
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 38px 24px 68px;
  position: relative;
  margin-bottom: 72px;
}

.digital__assets__cards__card__category {
  background: rgba(255, 255, 225, 0.2);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  position: absolute;
  right: 0;
  padding: 19px 37px;
  top: -36px;
}

.digital__assets__cards__card__category h4 {
  margin-bottom: 0;
}

.digital__assets__cards__card__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.digital__assets__cards__card__title__img {
  margin-right: 27px;
}

.digital__assets__cards__card__title h4 {
  margin-bottom: 0;
}

.digital__assets__cards__card__detail span {
  font-size: 20px;
  line-height: 27px;
  display: block;
  color: #FFFFFF;
  margin-bottom: 11px;
}

.digital__assets__cards__card__detail h4 {
  margin-bottom: 0;
  font-size: 22px;
}

.accreditation__steps {
  margin-top: 61px;
  padding-bottom: 200px;
}

.accreditation__steps__card {
  background: rgba(255, 255, 225, 0.2);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  max-width: 754px;
  margin: 0 auto 45px;
  padding: 101px 72px 68px;
  position: relative;
}

.accreditation__steps__card h3 {
  text-align: center;
  margin-bottom: 34px;
}

.accreditation__steps__card__points {
  margin-bottom: 53px;
}

.accreditation__steps__card__points h4 {
  text-align: center;
  font-size: 27px;
  line-height: 36px;
  margin-bottom: 20px;
}

.accreditation__steps__card__points .theme__list li span {
  color: #00FFDD;
}

.accreditation__steps__card__points.requirements__points {
  padding-left: 50px;
}

.accreditation__steps__card__btn {
  text-align: center;
}

.accreditation__steps__card__img {
  position: absolute;
  left: 50%;
  top: -43px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.accreditation__steps .digital__assets__heading {
  max-width: 1008px;
  margin: 109px auto 86px;
}

.tradeview__main {
  padding-bottom: 167px;
}

.tradeview__graph {
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 21px 19px;
  border-radius: 15px;
}

.tradeview__graph .graphmore {
  font-size: 23px;
  color: #fff;
}

.tradeview__widget {
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 36px 24px;
}

.tradeview__widget h4 {
  margin-bottom: 100px;
}

.tradeview__widget p {
  font-size: 20px;
  line-height: 27px;
}

.tradeview__widget .theme-btn {
  font-size: 18px;
  /* font-family: "Now-Bold"; */
  line-height: 28px;
  padding: 22px 20px;
  margin-top: 20px;
}

.tradeview__buy__n__sell {
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 36px 24px 20px;
  margin-top: 39px;
}

.tradeview__buy__n__sell hr {
  margin: 0;
  background: rgba(38, 50, 56, 0);
  border-radius: 3px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 6px;
}

.buy__n__sell__market__amount {
  margin-top: 20px;
}

.buy__n__sell__market__amount__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 14px;
}

.buy__n__sell__market__amount__title span {
  font-size: 20px;
  line-height: 27px;
  font-family: "Now-Medium";
  color: #fff;
  display: inline-block;
}

.buy__n__sell__market__amount__values {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.34);
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 8px 18px;
  border-radius: 30px;
}

.buy__n__sell__market__amount__values span {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  display: inline-block;
}

.buy__n__sell__order__detail {
  margin-top: 24px;
}

.buy__n__sell__order__detail h6 {
  font-size: 20px;
  line-height: 27px;
  font-family: "Now-Medium";
  color: #fff;
  margin-bottom: 8px;
}

.buy__n__sell__order__detail__list__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}

.buy__n__sell__order__detail__list__title span {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.buy__n__sell__order__detail__list__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.buy__n__sell__order__detail__list__content p {
  font-size: 20px;
  line-height: 27px;
  /* font-family: "Now-Bold"; */
  color: #fff;
  margin-bottom: 0;
}

.buy__n__sell__order__detail__btn .theme-btn {
  font-size: 18px;
  /* font-family: "Now-Bold"; */
  line-height: 28px;
  padding: 22px 20px;
  display: block;
  margin: 27px 0 32px;
}

.tradeview__mr {
  margin-top: 39px;
}

.securitytoken__bg {
  background: url(../img/securitytoken.png) no-repeat center;
  background-size: cover;
}

.security__token__banner__inner {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  padding-bottom: 110px;
}

.security__token__banner__box {
  background: rgba(0, 0, 0, 0.34);
  border-radius: 244px;
  opacity: 1;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  padding: 101px 110px 65px;
  text-align: center;
}

.security__token__banner__box h1 {
  margin-bottom: 27px;
}

.security__token__banner__box p {
  margin-bottom: 50px;
}

.security__token__banner__box .theme-btn {
  margin: 0 16px;
}

.security__token__banner__box .theme-btn:hover,
.security__token__banner__box .theme-btn:focus {
  background: transparent linear-gradient(104deg, #CC01FF 0%, #00FFDD 100%) 0% 0% no-repeat padding-box;
}

.our__partners .our__partners__inner {
  position: relative;
  z-index: 1;
}

.trading__process__steps .mobile__feature__wrapper {
  position: relative;
  z-index: 1;
}

.portfolio__sto {
  padding: 188px 0 0;
}

.portfolio__sto .mobile__feature__wrapper {
  position: relative;
  z-index: 1;
}

.portfolio__sto h2 {
  text-align: center;
  margin-bottom: 87px;
}

.portfolio__sto__box {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 46px 41px;
  border-radius: 60px;
  margin: 0 40px;
}

.portfolio__sto__box__media {
  display: inline-block;
  position: relative;
}

.portfolio__sto__box__media__category {
  background: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 10px 6px #00000029;
  box-shadow: 0px 10px 6px #00000029;
  border-radius: 34px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 15px 64px;
  display: inline-block;
  position: absolute;
  bottom: -30px;
  right: 0;
}

.portfolio__sto__box__media__category p {
  margin-bottom: 0;
  color: #fff;
  /* font-family: "Now-Bold"; */
}

.portfolio__sto__box__content {
  margin-top: 68px;
}

.portfolio__sto__box__content h4 {
  margin-bottom: 13px;
}

.portfolio__sto__box__content p {
  font-size: 1.25rem;
  line-height: 27px;
}

.portfolio__sto__box__feature {
  margin-top: 21px;
  margin-bottom: 30px;
}

.portfolio__sto__box__feature span {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 11px;
}

.portfolio__sto__box__feature h4 {
  text-align: center;
}

.portfolio__sto__box__btn {
  text-align: center;
}

.slick-prev {
  width: 66px;
  height: 66px;
  background: url(../img/leftarrow.svg) no-repeat;
  left: -75px;
}

.slick-prev::before {
  display: none;
}

.slick-next {
  width: 66px;
  height: 66px;
  background: url(../img/rightarrow.svg) no-repeat;
  right: -75px;
}

.slick-next::before {
  display: none;
}

.slick-prev:hover,
.slick-prev:focus {
  background: url(../img/leftarrow.svg) no-repeat;
}

.slick-next:hover,
.slick-next:focus {
  background: url(../img/rightarrow.svg) no-repeat;
}

.upcoming__sto {
  padding-top: 135px;
}

.sto__features {
  padding-bottom: 151px;
}

.sto__features .app__feature__steps__box__text {
  max-width: 444px;
}

.sto__features .app__feature__steps__box__img {
  top: -3px;
}

.sto__features .app__feature__steps__box {
  margin: 122px 0;
}

.sto__features .app__feature__steps__box p {
  font-size: 17px;
}

.sto__features .app__feature__steps__box .theme__list li {
  font-size: 17px;
}

.sto__features .app__feature__steps__box h4 {
  min-height: 67px;
}

.sto__features .app__feature__steps .app__feature__steps__box:nth-child(even) .app__feature__steps__box__text {
  margin-left: auto;
}

.sto__features .app__feature__steps .app__feature__steps__box:nth-child(even) .app__feature__steps__box__img {
  right: auto;
  left: 0;
}

.trading__process__steps {
  border-radius: 115px;
  margin-top: -115px;
  padding-top: 60px;
  padding-bottom: 190px;
}

.trading__process__steps__header {
  text-align: center;
  margin-bottom: 72px;
}

.trading__process__steps__header h3 {
  margin-bottom: 36px;
}

.trading__process__steps__step {
  background: rgba(255, 255, 225, 0.1);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 72px 15px 19px;
  max-width: 812px;
  margin: 0 auto 54px;
  position: relative;
}

.trading__process__steps__step:last-of-type {
  margin-bottom: 0;
}

.trading__process__steps__step__text {
  padding: 0 40px 24px;
}

.trading__process__steps__step__count {
  background: rgba(255, 255, 225, 0.1);
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  position: absolute;
  left: 0;
  top: -36px;
  padding: 19px 29px;
}

.trading__process__steps__step__count p {
  margin-bottom: 0;
  /* font-family: "Now-Bold"; */
  font-size: 28px;
}

.trading__process__steps__step__btn {
  text-align: right;
}

.trading__process__steps__step .theme-btn {
  background: rgba(0, 0, 0, 0.34);
  padding: 9px 31px;
  font-size: 27px;
  line-height: 49px;
}

.trading__simpler__faster {
  text-align: center;
  padding: 111px 0 50px;
}

.trading__simpler__faster h3 {
  margin-bottom: 52px;
}

.sto__faster__simpler__detail h3 {
  text-align: center;
}

.sto__faster__simpler__detail .img-center {
  padding: 178px 0 209px;
}

.sto__faster__simpler__detail__options__option {
  background: rgba(255, 255, 225, 0.1);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 122px 45px 39px;
  text-align: center;
  position: relative;
}

.sto__faster__simpler__detail__options__option h4 {
  margin-bottom: 42px;
  font-size: 21px;
}

.sto__faster__simpler__detail__options__option p {
  font-size: 18px;
  line-height: 27px;
}

.sto__faster__simpler__detail__options__option__img {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -86px;
  width: 172px;
  height: 172px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.35);
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.our__partners {
  padding-top: 80px;
  padding-bottom: 269px;
  margin-top: -115px;
}

.our__partners__inner {
  max-width: 992px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.our__partners .theme__h3__heading {
  margin-bottom: 234px;
}

.our__partners__partner {
  background: rgba(255, 255, 225, 0.1);
  border-radius: 46px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  min-height: 234px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.theme__h3__heading {
  text-align: center;
}

.theme__h3__heading h3 {
  margin-bottom: 52px;
}

.sto__purchase___form {
  padding-top: 207px;
  padding-bottom: 139px;
}

.tab__header {
  text-align: center;
}

.signup__now__bar {
  margin-top: 78px;
  margin-bottom: 158px;
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 49px 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.signup__now__bar .theme-btn {
  padding: 24px 28px;
  font-size: 28px;
}

.signup__now__bar h3 {
  margin-bottom: 0;
}

.sto__purchase___form__gap {
  margin-top: 63px;
}

.sto__purchase___form__btn {
  text-align: center;
  margin-top: 38px;
}

.sto__purchase___form__btn .theme-btn {
  font-size: 28px;
  background: rgba(255, 255, 255, 0.1);
}

.field-icon {
  float: right;
  margin-left: -78px;
  margin-top: -42px;
  position: relative;
  z-index: 2;
  font-size: 30px;
  color: #fff;
  right: 18px;
}

.investor__portal__signup__main {
  padding-top: 304px;
  padding-bottom: 150px;
}

.sto__circles .big__right__circle {
  top: 9%;
}

.sto__circles .big__left__circle {
  top: 21%;
}

.sto__circles .big__right__one__circle {
  right: -50px;
  top: 48%;
  z-index: 0;
  max-width: 700px;
}

.sto__circles .big__left__one__circle {
  left: -50px;
  top: 73%;
  z-index: 0;
  max-width: 700px;
}

.investor__portal__welcome .security__token__banner__box {
  border-radius: 244px;
}

.investor__portal__welcome .security__token__banner__box h1 {
  text-shadow: 0px 30px 30px #000000B8;
}

.investor__portal__welcome .theme__list {
  margin-bottom: 38px;
  text-align: left;
}

.investor__portal__welcome .theme__list li {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.wallet__main {
  padding-top: 97px;
  padding-bottom: 107px;
}

.wallet__main__inner {
  max-width: 1400px;
  margin: auto;
}

.wallet__main__accounts {
  background: rgba(0, 0, 0, 0.44);
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 32px 16px;
  border-radius: 22px;
}

.wallet__main__accounts h5 {
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 29px;
}

.wallet__main__accounts ul {
  padding: 0 18px;
}

.wallet__main__accounts ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 39px;
}

.wallet__main__accounts ul li:last-of-type {
  margin-bottom: 0;
}

.wallet__main__accounts ul li .currency__img {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.wallet__main__accounts ul li .currency__img img {
  -o-object-fit: contain;
  object-fit: contain;
}

.wallet__main__accounts ul li .currency__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wallet__main__accounts ul li .currency__info p {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 27px;
  /* font-family: "Now-Bold"; */
  margin-right: 24px;
  text-align: right;
}

.wallet__main__accounts ul li .currency__btn {
  min-width: 110px;
  margin-right: 17px;
}

.wallet__main__accounts ul li .currency__btn:last-of-type {
  margin-right: 0;
}

.wallet__main__accounts ul li .currency__btn a {
  width: 100%;
  text-align: center;
}

.profile__kyc__tabs {
  padding-top: 81px;
}

.profile__kyc__tabs__icon {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.34);
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
}

.profile__kyc__tabs .nav-link {
  font-size: 18px;
  color: #fff;
  /* font-family: "Now-Bold"; */
  max-width: 114px;
  margin: 0 20px;
  text-align: center;
  padding: 0;
}

.profile__kyc__tabs .nav-link.active {
  background-color: transparent;
}

.profile__kyc__tabs .nav-link.active .profile__kyc__tabs__icon {
  background: rgba(204, 1, 255, 0.34);
}

.profile__bg {
  background: rgba(0, 0, 0, 0.35);
  border-radius: 33px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.profile__kyc__content__intro {
  padding: 42px 38px;
}

.profile__kyc__content__intro h5 {
  font-size: 26px;
  line-height: 55px;
  margin-bottom: 26px;
}

.profile__kyc__content__intro p {
  font-size: 20px;
  line-height: 30px;
}

.profile__kyc__content {
  padding-top: 101px;
  padding-bottom: 110px;
}

.profile__kyc__content__intro__btns {
  margin-top: 16px;
  text-align: right;
}

.profile__kyc__content__intro__btns .theme-btn {
  font-size: 21px;
  line-height: 28px;
  /* font-family: "Now";  */
  font-family: "DM Sans";
  font-weight: 400;
  margin: 0 5px;
}

.profile__kyc__content__intro__btns .theme-btn:last-of-type {
  margin-right: 0;
}

.kyc__users {
  margin-top: 67px;
}

.kyc__users__user {
  position: relative;
  padding: 60px 45px 22px;
  min-height: 449px;
}

.kyc__users__user__box {
  background: rgba(3, 0, 23, 0.55);
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -43px;
}

.kyc__users__user h4 {
  text-align: center;
  font-size: 25px;
}

.kyc__users__user .theme__list {
  padding-top: 16px;
  padding-bottom: 39px;
  min-height: 260px;
}

.kyc__users__user .theme__list li {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 11px;
}

.kyc__users__user .theme__list li:last-of-type {
  margin-bottom: 0;
}

.kyc__users__user__requirement h6 {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  /* font-family: "Now-Bold"; */
  margin-bottom: 25px;
}

.kyc__users__user__requirement p {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0;
  min-height: 38px;
}

.kyc__users__user__btn {
  margin-top: 17px;
}

.kyc__users__user__btn .theme-btn {
  font-size: 14px;
  line-height: 19px;
  border-radius: 28px;
  padding: 13px 30px;
  display: block;
}

.kyc__users__user__btn .theme-btn:hover {
  color: #1DFA06;
}

.profile__view {
  margin-top: 62px;
}

.profile__view__box {
  padding: 33px 38px;
  border-radius: 12px;
  margin-bottom: 8px;
}

.profile__view__box:last-of-type {
  margin-bottom: 0;
}

.profile__view__group {
  margin-bottom: 36px;
}

.profile__view__group:last-of-type {
  margin-bottom: 0;
}

.profile__view__group h6 {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 8px;
  font-family: "Now-Medium";
}

.profile__view__group h6 span {
  font-size: 12px;
  color: #C3C3C3;
  margin-left: 8px;
}

.profile__view__group .form-control[readonly] {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #C3C3C3;
}

.profile__view__group .input__text {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #C3C3C3;
  max-width: 240px;
  display: block;
}

.profile__view__group .form-switch .form-check-input {
  background-color: transparent;
  border-color: #fff;
  border-width: 3px;
  width: 42px;
  height: 26px;
}

.profile__view__group .form-switch .form-check-input:checked {
  background-color: rgba(96, 0, 132, 0.3);
}

.trade__reports {
  margin-bottom: 67px;
}

.trade__reports h5 {
  margin-bottom: 31px;
  padding-left: 54px;
}

.portable__table table thead tr th {
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 19px;
  padding: 31px 18px 16px 18px;
  /* font-family: "Now-Bold"; */
  color: #FFFFFF;
}

.portable__table table thead tr th:first-of-type {
  padding-left: 63px;
}

.portable__table table tfoot tr th {
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 19px;
  padding: 16px 18px 31px 18px;
  /* font-family: "Now-Bold"; */
  color: #FFFFFF;
  border-top-width: 1px;
  border-bottom-width: 0px;
}

.portable__table table tfoot tr th:first-of-type {
  padding-left: 63px;
}

.portable__table table tbody tr td {
  font-size: 14px;
  line-height: 19px;
  padding: 18px;
  color: #FFFFFF;
  vertical-align: middle;
  border-color: #6F67CA;
}

.portable__table table tbody tr td:first-of-type {
  padding-left: 63px;
}

.portable__table table tbody tr:last-of-type td {
  border-bottom: 0;
}

.portable__table .norecord {
  padding: 31px 63px;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  display: block;
}

.theme-btn.with-border {
  border: 3px solid #6fea4b;
}

.api__keys__box {
  margin: 23px 0 32px;
}

.api__keys__box .apikeys__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.api__keys__box .apikeys__box .api__keys__text a {
  color: #00FFDD;
  font-size: 14px;
  line-height: 19px;
}

.api__keys__box .apikeys__box .api__keys__actions .form-check-label {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.usercontacts__gap {
  margin-top: 46px;
}

.current__tag__update {
  padding: 31px 63px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.current__tag__update p {
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.update__current__tag .form-label {
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.update__current__tag .form-control {
  background: rgba(255, 255, 255, 0.35);
  color: #fff;
  border-radius: 28px;
  opacity: 1;
  backdrop-filter: blur(0);
  -webkit-backdrop-filter: blur(0);
  max-width: 194px;
}

.update__current__tag .theme-btn {
  font-size: 14px;
  line-height: 18px;
  /* font-family: "Now-Bold"; */
  height: 55px;
}

.checkbox label {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
}

.checkbox .cr .cr-icon {
  position: absolute;
  line-height: 0;
  top: 52%;
  left: 24%;
  font-size: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}

.portal__tradeview {
  padding-top: 170px;
  padding-bottom: 176px;
}

.portal__tradeview__boxes__main {
  border-radius: 19px;
  padding: 7px 14px 15px;
  margin-bottom: 16px;
  min-height: 68px;
}

.portal__tradeview__boxes__main h5,
.portal__tradeview__boxes__main button {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 27px;
  background-color: transparent;
  border: none;
  color: #fff;
}

.portal__tradeview__boxes__main p {
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  margin-bottom: 0;
}

.portal__tradeview__boxes__main button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  /* font-family: "Now-Bold"; */
  width: 100%;
  padding-top: 13px;
}

.portal__tradeview__boxes__main button:after {
  margin-left: auto;
}

.trade__view__wrap {
  max-width: 862px;
  margin: auto;
}

.order__book__box {
  padding: 18px 16px;
  border-radius: 19px;
  height: 100%;
}

.order__book__box h5 {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 27px;
}

.order__book__box .table th,
.order__book__box .table td {
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  font-weight: 400;
}

.tradegraph {
  padding: 46px 56px 19px;
}

.tradeview__boxes__main {
  padding-top: 20px;
}

.order__entries {
  padding: 10px 16px 26px 24px;
  height: 100%;
}

.order__entries h6 {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  /* font-family: "Now-Bold"; */
}

.order__entries span,
.order__entries label {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
}

.order__entries>h6 {
  margin-bottom: 26px;
}

.order__entries__amount__buy {
  margin-bottom: 23px;
}

.order__entries__amount__buy__valuefield {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.order__entries__amount__buy__valuefield .form-label {
  margin-bottom: 0;
  margin-right: 13px;
}

.order__entries__amount__buy__valuefield .form-control {
  padding: 6px 14px;
  border-radius: 30px;
  background: #171E22;
  font-size: 14px;
  line-height: 19px;
}

.order__entries__amount__buy span {
  display: block;
  margin-bottom: 11px;
}

.order__entries__market__prices__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order__entries__market__prices__price h6 {
  margin-bottom: 0;
}

.order__entries__btn {
  padding-top: 11px;
}

.order__entries__btn .theme-btn {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  padding: 13px 60px;
  border-radius: 28px;
  background: #171E22;
  font-weight: 400;
  /* font-family: "Now";  */
  font-family: "DM Sans";
}

.order__entries__inner {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order__entries__content>h6 {
  margin-bottom: 26px;
}

.timeforce__select {
  margin-bottom: 17px;
}

.timeforce__select .form-select {
  padding: 8px 14px;
  border-radius: 30px;
  background: #171E22;
  font-size: 11px;
  line-height: 15px;
  color: #fff;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.timeforce__select .form-select:focus {
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.timeforce__select .form-select+img {
  float: right;
  margin-top: -25px;
  margin-right: 12px;
  pointer-events: none;
  padding-right: 5px;
}

.balance__table {
  margin-bottom: 88px;
}

.asset__token__banner__btns .theme-btn {
  padding-left: 60px;
  padding-right: 60px;
}

.asset__tokenization__partners {
  padding-bottom: 192px;
  position: relative;
  z-index: 3;
}

.asset__tokenization__partners__inner {
  max-width: 1612px;
  margin: auto;
}

.asset__tokenization__partners__partner {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 46px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  width: 267px;
  height: 98px;
  padding: 16px 32px;
}

.asset__tokenization__partners__partner img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.asset__tokenization__sli {
  padding-top: 94px;
  padding-bottom: 30px;
}

.asset__tokenization__sli .trading__process__steps__header {
  max-width: 683px;
  margin-left: auto;
  margin-right: auto;
}

.competition__boxes {
  padding-bottom: 98px;
  border-radius: 115px;
  margin-top: -155px;
  position: relative;
}

.competition__boxes .mobile__feature__wrapper {
  z-index: 3;
  position: relative;
}

.competition__boxes__box .cryptox__feature__box {
  margin: 0 auto 140px;
  background: rgba(255, 255, 255, 0.1);
}

.competition__boxes__box .cryptox__feature__box h3 {
  margin-bottom: 40px;
}

.competition__boxes__box .cryptox__feature__box ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 23px;
}

.competition__boxes__box .cryptox__feature__box ul li:last-of-type {
  margin-bottom: 0;
}

.competition__boxes__box .cryptox__feature__box ul li i {
  font-size: unset;
}

.competition__boxes__box .cryptoimage {
  min-height: auto;
  margin-bottom: -16px;
}

.competition__boxes__box .cryptox__btn .theme-btn {
  background: rgba(0, 0, 0, 0.34);
  font-size: 27px;
  line-height: 49px;
  padding: 6px 16px;
}

.digital__securties {
  padding-top: 253px;
  margin-top: -115px;
  padding-bottom: 149px;
}

.digital__securties .mobile__feature__wrapper {
  position: relative;
  z-index: 3;
}

.digital__securties .theme__h3__heading {
  margin-bottom: 170px;
}

.digital__securties .sto__faster__simpler__detail__options__option h4 {
  margin-bottom: 28px;
  min-height: 50px;
}

.digital__securties .sto__faster__simpler__detail__options__option ul {
  margin-bottom: 11px;
}

.digital__securties .sto__faster__simpler__detail__options__option ul:last-of-type {
  margin-bottom: 0;
}

.digital__securties .sto__faster__simpler__detail__options__option ul li {
  letter-spacing: 0px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
}

.digital__securties .sto__faster__simpler__detail__options__option__img {
  background: transparent;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
}

.digital__securties .sto__faster__simpler__detail {
  padding-bottom: 200px;
}

.signup__now__bar__content {
  text-align: right;
  padding-left: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.signup__now__bar__content h3 {
  margin-bottom: 15px;
}

.sto__launch {
  background: rgba(255, 255, 225, 0.2);
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  border-radius: 43px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  max-width: 460px;
  margin: 100px auto 0;
  padding: 100px 60px 80px;
  text-align: center;
}

.sto__launch h3 {
  margin-bottom: 62px;
}

.asset__tokenization__circle .big__right__circle {
  top: 10.5%;
  z-index: 2;
}

.asset__tokenization__circle .big__left__circle {
  top: 27%;
  z-index: 2;
}

.asset__tokenization__circle .big__right__one__circle {
  z-index: 2;
  top: 50.5%;
}

.ast__banner {
  padding-top: 194px;
}

.ast__banner .security__token__banner__inner {
  min-height: auto;
  display: block;
}

.ast__banner .security__token__banner__box h1 {
  text-shadow: 0px 30px 30px #000000B8;
}

.ast__banner .security__token__banner__box p {
  text-shadow: 0px 30px 20px #000000C4;
  max-width: 789px;
  margin-left: auto;
  margin-right: auto;
}

.ast__banner .ast__img {
  text-align: center;
  margin-bottom: -86px;
  z-index: 1;
  position: relative;
}

.green-color {
  color: #00FFDD;
}

.asset__tokenization__f {
  padding-bottom: 224px;
  z-index: 3;
  position: relative;
}

.asset__tokenization__f .sto__faster__simpler__detail__options__option h4 {
  font-size: 28px;
  min-height: 68px;
}

.asset__tokenization__f .sto__faster__simpler__detail {
  padding-top: 51px;
}

.ast__compitetion__boxes {
  padding-top: 118px;
}

.ast__compitetion__boxes .cryptoimage {
  margin-bottom: 0;
}

.ast__screen__circles .big__right__circle {
  top: 16%;
}

.ast__screen__circles .big__left__circle {
  top: 34%;
}

.ast__screen__circles .big__right__one__circle {
  z-index: 2;
  top: 66%;
}

.request__demo__main {
  padding-top: 207px;
  padding-bottom: 73px;
}

.request__demo__main__header {
  margin-bottom: 60px;
  text-align: center;
}

.request__demo__main__header h1 {
  margin-bottom: 34px;
}

.request__demo__main__form .login__form__main {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 53px;
  opacity: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  padding: 79px 90px 97px;
}

.request__demo__main__form button {
  margin-top: 83px;
}

.asset__tokenization__sli .trading__process__steps {
  position: relative;
  z-index: 3;
}

.dashboard {
  display: inline-block;
  width: 100%;
}

.dashboard__bg {
  background-color: #37004B;
}

.dashboard__wrapper {
  padding: 0 15px;
  max-width: 1122px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard__header__box {
  margin-top: 83px;
  background: #600084 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 30px 60px #00000029;
  box-shadow: 0px 30px 60px #00000029;
  padding: 30px 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 25px;
}

.dashboard__header__box h4 {
  margin-bottom: 0;
  font-size: 26px;
  line-height: 55px;
}

.dashboard__market__overview {
  background: #600084 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 40px 42px;
  margin-top: 41px;
}

.dashboard__market__overview h4 {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 50px;
}

.dashboard__market__overview__currency {
  margin-bottom: 64px;
}

.dashboard__market__overview__currency ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__market__overview__currency ul li {
  margin: 0 19px;
}

.dashboard__market__overview__currency ul li:last-of-type {
  margin-right: 0;
}

.dashboard__market__overview__currency ul li:first-of-type {
  margin-left: 0;
}

.dashboard__market__overview__currency ul li a {
  font-size: 18px;
  line-height: 24px;
  color: #F5FBFF;
}

.market__overflow__currency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.market__overflow__currency__title h5 {
  color: #F5FBFF;
  /* font-family: "Now-Bold"; */
  font-size: 27px;
  line-height: 36px;
  margin-bottom: 0;
}

.market__overflow__currency__title p {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #F5FBFF;
  margin-bottom: 0;
}

.market__overflow__currency__img {
  position: relative;
  margin-right: 25px;
  min-width: 146px;
  min-height: 50px;
}

.market__overflow__currency__img__one {
  position: absolute;
  top: -38px;
  left: 0;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  overflow: hidden;
}

.market__overflow__currency__img__one img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.market__overflow__currency__img__two {
  position: absolute;
  left: 39px;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  overflow: hidden;
}

.market__overflow__currency__img__two img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.market__overflow__currency__values {
  margin-top: 88px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 36px;
}

.market__overflow__currency__values h4 {
  margin-bottom: 0;
  margin-right: 22px;
}

.market__overflow__currency__values p {
  color: #F5FBFF;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
}

.balance__box {
  background: #600084 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 32px 16px;
  margin-top: 40px;
}

.balance__box h4 {
  margin-bottom: 30px;
  font-size: 26px;
  line-height: 35px;
}

.balance__box ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40.7px;
}

.balance__box ul li:last-of-type {
  margin-bottom: 0;
}

.balance__box ul li img {
  max-width: 48px;
  margin-right: 17px;
}

.balance__box ul li p {
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 0;
}

.recent__activity__main {
  background: #600084 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 34px 32px;
  margin-top: 22px;
  margin-bottom: 118px;
}

.recent__activity__main__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.recent__activity__main__header h4 {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 0;
}

.recent__activity__main__header .theme-btn {
  /* font-family: "Now";  */
  font-family: "DM Sans";
}

.recent__activity__main__table {
  margin-bottom: 0;
}

.recent__activity__main__table th,
.recent__activity__main__table td {
  padding-left: 18px !important;
}

.dashboard-box-bg {
  background: #600084;
}

.dashboard__wallet .wallet__main__inner {
  max-width: 1430px;
  margin: auto;
}

.instant__order {
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 31px 32px 68px;
}

.instant__order span {
  font-size: 26px;
  line-height: 35px;
  color: #fff;
  display: block;
  margin-bottom: 49px;
}

.instant__order h4 {
  font-size: 26px;
  line-height: 35px;
}

.instant__order__btns {
  padding: 69px 0 44px;
}

.instant__order__btns a {
  margin: 0 11px;
  min-width: 150px;
}

.instant__order__btns a:last-of-type {
  margin-right: 0;
}

.instant__order__btns a:first-of-type {
  margin-left: 0;
}

.instant__order .form-select {
  font-size: 21px;
  padding: 15px 48px;
  line-height: 25px;
}

.instant__order .form-select+img {
  margin-top: -36px;
  margin-right: 22px;
}

.instant__order .timeforce__select {
  margin-bottom: 44px;
}

.instant__order__ammount {
  margin-bottom: 46px;
  max-width: 517px;
}

.instant__order__ammount ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 28px;
}

.instant__order__ammount ul li:last-of-type {
  margin-bottom: 0;
}

.instant__order__ammount__check {
  min-width: 130px;
}

.instant__order__ammount__check input[type=checkbox]+label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
}

.instant__order__ammount__check input[type=checkbox] {
  display: none;
}

.instant__order__ammount__check input[type=checkbox]+label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s;
  margin-right: 47px;
}

.instant__order__ammount__check input[type=checkbox]:checked+label:before {
  background-color: #00CCF2;
  border-color: #00CCF2;
  color: #fff;
}

.instant__order__ammount__right {
  font-size: 19px;
  color: #fff;
}

.instant__order__ammount__text {
  min-width: 92px;
  text-align: left;
  color: #fff;
}

.instant__order__btn .theme-btn {
  color: #F5FBFF;
  font-size: 21px;
  line-height: 28px;
  padding: 13px 60px;
  border-radius: 30px;
  /* font-family: "Now";  */
  font-family: "DM Sans";
  font-weight: 400;
}

.converts__main {
  padding: 180px 0;
}

.convert__currency__detail {
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 60px 32px 88px;
}

.convert__currency__detail__box {
  margin-bottom: 49px;
}

.convert__currency__detail p {
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 0;
}

.convert__currency__detail span {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

.convert__currency__detail__transection ul {
  margin-top: 47px;
}

.convert__currency__detail__transection ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.convert__currency__detail__transection ul li:last-of-type {
  margin-bottom: 0;
}

.creditcard__screen {
  padding: 180px 0 76px;
}

.creditcard__screen__inner {
  max-width: 959px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.credit__buying {
  margin-bottom: 51px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  border-radius: 20px;
  padding: 60px 49px 86px;
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
}

.credit__buying__field {
  margin-bottom: 25px;
}

.credit__buying__field:last-of-type {
  margin-bottom: 0;
}

.credit__buying__field span {
  color: #fff;
  text-align: center;
  font-size: 26px;
  line-height: 35px;
  display: block;
  margin-bottom: 30px;
}

.credit__buying__field .form-select {
  color: #F5FBFF;
  font-size: 21px;
  line-height: 28px;
  padding: 13px 48px;
}

.credit__buying__field .form-select+img {
  margin-top: -35px;
  margin-right: 18px;
}

.credit__view__box {
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
  padding: 38px 32px 33px;
  margin-bottom: 27px;
  min-height: 428px;
}

.credit__view__box__coin {
  text-align: center;
  margin-bottom: 46px;
}

.credit__view__box__list {
  margin-bottom: 63px;
  list-style: none;
}

.credit__view__box__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
}

.credit__view__box__list li:last-of-type {
  margin-bottom: 0;
}

.credit__view__box__list li p {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 0;
}

.credit__view__box__btn {
  text-align: center;
}

.credit__view__box__btn a {
  font-size: 21px;
  line-height: 28px;
  color: #F5FBFF;
  padding: 13px 39px;
  border-radius: 30px;
  font-weight: 400;
  /* font-family: "Now";  */
  font-family: "DM Sans";
}

.editable__usd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.editable__usd .form-control {
  width: 92px;
  background: #171E22 0% 0% no-repeat padding-box;
  border-radius: 28px;
  height: 55px;
  margin-right: 8px;
}

.profile__verfication__dash .portable__table {
  border-radius: 20px;
}

.profile__verfication__dash .update__current__tag .form-control {
  background: black;
}

.profile__verfication__dash .update__current__tag .theme-btn {
  background: #CC01FF;
}

.dashboard__tradeview .portable__table {
  border-radius: 20px;
}

.activity__history__main {
  padding: 50px 0;
}

.activity__history__filter {
  margin-top: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity__history__filter__products {
  width: 264px;
}

.activity__history__filter__products .form-select {
  color: #F5FBFF;
  font-size: 21px;
  line-height: 28px;
  padding: 13px 42px;
  background: #171E22 0% 0% no-repeat padding-box;
  border-radius: 28px;
}

.activity__history__filter__products .form-select+img {
  margin-top: -36px;
  margin-right: 15px;
}

.activity__history__filter__products .order__entries__amount__buy {
  margin-bottom: 0;
}

.activity__history__filter__dates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity__history__filter__dates__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity__history__filter__dates__date span {
  color: #F5FBFF;
  font-size: 21px;
  line-height: 28px;
  margin-right: 15px;
}

.activity__history__filter__dates__date__max {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.activity__history__filter__dates__date__max .form-control {
  color: #F5FBFF;
  font-size: 21px;
  line-height: 28px;
  padding: 13px 35px;
  border-radius: 30px;
  background: #171E22;
  max-width: 194px;
}

.dashboard-btn {
  font-size: 21px;
  line-height: 28px;
  color: #F5FBFF;
  padding: 13px 39px;
  border-radius: 30px;
  font-weight: 400;
  /* font-family: "Now";  */
  font-family: "DM Sans";
  background: #600084;
}

.balance__btn {
  margin: 32px 0 51px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.product__balance {
  padding-top: 50px;
  padding-bottom: 100px;
}

.product__balance__intro {
  background: #600084 0% 0% no-repeat padding-box;
  border-radius: 33px;
  padding: 53px 38px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product__balance__intro h4 {
  font-size: 25px;
  line-height: 55px;
  color: #fff;
  margin-bottom: 0;
}

.product__balance__intro p {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0;
}

.product__balance__value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.currency__img__blanace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.currency__img__blanace__img {
  width: 30px;
  height: 30px;
}

.currency__img__blanace__img img {
  -o-object-fit: contain;
  object-fit: contain;
}

.currency__img__blanace span {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
}

.product__balance .portable__table {
  margin-top: 22px;
}

.product__balance .portable__table table tbody tr td {
  border-bottom-width: 0;
}

.product__balance .portable__table table tbody tr td:first-of-type {
  border-bottom-width: 1px;
}

.product__balance .portable__table table tbody tr td:nth-child(2) {
  border-bottom-width: 1px;
}

.product__balance .portable__table table tbody tr td:not(:first-of-type) {
  text-align: center;
}

.product__balance .portable__table table thead tr th:not(:first-of-type),
.product__balance .portable__table table tfoot tr th:not(:first-of-type) {
  text-align: center;
}

.product__balance .portable__table table tbody tr td:first-of-type {
  padding-left: 30px;
}

.product__balance .portable__table table thead tr th:first-of-type {
  padding-left: 30px;
}

.product__balance .portable__table table tfoot tr th:first-of-type {
  padding-left: 30px;
}

.mobile__header {
  text-align: center;
  max-width: calc(100% - 300px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.mobile__header span {
  font-size: 34px;
  color: #fff;
}

.mobile__header span:hover {
  color: #fff;
}

.header__mobile__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.asset-activity-details-header__select-wrapper,
.asset-activity-details-header__from__dpk-wrapper,
.asset-activity-details-header__to__dpk-wrapper,
.activity__select-wrapper,
.activity__from__dpk-wrapper,
.activity__to__dpk-wrapper {
  margin-bottom: 0 !important;
}

.asset-activity-details-header__select,
.asset-activity-details-header__date-pickers-container .ap-datepicker__input,
/* .asset-activity-details-header__clear__btn, */
.activity__to__dpk-input,
.activity__from__dpk-input,
.activity__select {
  font-size: 1.6rem !important;
  width: fit-content !important;
  /* height: 3.2rem; */
  height: auto;
}

.activity__select {
  font-size: 1.6rem !important;
  min-width: 16rem !important;
  width: auto !important;
}

.ap-select__triangles-container {
  z-index: 1;
  bottom: 1rem;
  pointer-events: none;
  right: .8rem;
}

.asset-activity-details-header__clear__btn {
  margin-right: 0.5rem !important;
}

.download-report__body .flex-table__header-cell,
.schedule-report-list .flex-table__header-cell {
  position: unset !important;
}

.download-report__row {
  height: 5rem !important;
}

.retail-api-key-list {
  border-radius: 0;
}

.affiliate__btn {
  border: none;
  padding: 0.5rem 2rem;
  height: auto !important;
}

.activity__select-buttons {
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.activity__btn--clear-filters {
  margin-left: auto;
}

.activity__triangles-container {
  z-index: 1;
  right: 0.5rem;
  left: unset;
}

.transfers-filters__rt-label {
  font-size: 1.4rem !important;
  color: #ffffff !important;
}

.transfers-filters__sb-container {
  min-width: auto !important;
}

.transfers-filters__rt-label--checked {
  background: var(--color-secondary) !important;
}

.send-receive-confirm-header__icon {
  width: 3.6rem !important;
}

.sidepane-details__information--top {
  margin-top: -40rem;
}

.order-entry__triangles-container {
  right: 2.5rem !important;
}

.asset-activity-details-header__to__dpk-label {
  margin: 0 1.1rem !important;
}

.asset-activity-details-header__select-label {
  display: none !important;
}

.asset-activity-details-header__from__dpk-label,
.activity__select-label,
.activity__from__dpk-label,
.activity__to__dpk-label {
  margin-bottom: 0 !important;
}

.activity__from__dpk-label {
  margin: 0 0.75rem 0 3rem !important;
}

.activity__to__dpk-label {
  margin: 0 0.75rem;
}

.activity__select-buttons .ap--label {
  margin-right: 0.75rem !important;
}

.activity__type-icon {
  width: 3.6rem !important;
  display: flex !important;
}

.download-report__column {
  text-align: left !important;
}

.download-report__action-btn__btn {
  display: flex !important;
  align-items: center !important;
  padding: .8rem 1.25rem;
  border-radius: 16px;
  justify-content: center;
}

.transfers__fixed {
  position: static !important;
  margin-top: 0 !important;
}

.transfers__header-column,
.transfers__column {
  text-align: left;
}

.transfers__header-column--amount,
.transfers__column--amount {
  text-align: right;
}

.icon-button__icon {
  display: flex !important;
  width: 2rem !important;
}

.report-form__select,
.report-form__input,
.report-form__dpk-input {
  height: 4.4rem !important;
}

.standalone-modal__footer {
  margin: 1rem 2.8rem !important;
}
.standalone-modal__btn {
  font-size: 1.4rem !important;
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  padding: 0 1rem;
  border: 1px solid transparent;
  color: var(--font__color-primary);
  white-space: nowrap;
  padding: .8rem 1.25rem !important;
  background: var(--color-secondary) !important;
  border-radius: 90px;
  height: auto;
  line-height: normal;
  position: relative;
  overflow: hidden;
  width: 100% !important;
}
.report-form__triangles-container {
  top: 4rem !important;
}

.transfers__primary-text {
  white-space: initial;
}

.transfers__column--status .transfers__primary-text,
.affiliate__container-content {
  word-break: break-all;

}

.order-entry__input-wrapper {
  margin-bottom: 0px!important;
}

@media (max-width: 1536px) {

  .sidebar__main .mainlinks__sidebar>a,
  .sidebar__main .helplinks__sidebar>a {
    margin-bottom: 42px;
  }

  .circles__object {
    opacity: 0;
    /* display: none !important; */
  }

  .get__started__boxes__inner,
  .cryptox__feature__main__inner,
  .how__it__work__inner,
  .seen__on__media__inner {
    max-width: 1160px;
  }

  h1 {
    font-size: 62px;
  }

  .sidebar__main .brand__logo {
    margin-bottom: 70px;
  }

  .sidebar__main.right__sidebar .account__icon {
    text-align: right;
    margin-bottom: 100px;
  }

  .banner__main__inner>img {
    max-width: 50%;
  }

  p {
    font-size: 24px;
    line-height: 28px;
  }

  .banner__main__inner .banner__btns {
    margin-top: 46px;
  }

  .theme-btn {
    font-size: 24px;
    line-height: 37px;
    padding: 18px 60px;
  }

  .banner__main__inner .banner__btns .theme-btn {
    margin: 0px 8px;
  }

  .cryptolist__main .coin__box p {
    font-size: 21px;
  }

  .cryptolist__main .coin__box span {
    font-size: 18px;
  }

  .cryptolist__main .coin__box .theme-btn {
    padding: 13px 35px;
  }

  .cryptolist__main {
    max-width: 715px;
  }

  h3 {
    font-size: 38px;
  }

  .cryptox__feature__box p {
    font-size: 21px;
  }

  .cryptox__feature__box ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 21px;
  }

  .cryptoimage {
    margin-bottom: -42px;
  }

  .cryptox__feature__main .cryptox__feature__box {
    min-height: 675px;
    max-width: 550px;
    padding: 40px 45px;
  }

  .crypto__boxes__box h3 {
    min-height: 92px;
  }

  h2 {
    font-size: 62px;
  }

  .seen__on__media__box {
    padding: 26px;
  }

  .media__cat {
    padding: 12px 23px;
  }

  .seen__on__media__box .seen__title h4 {
    font-size: 20px;
    min-height: 96px;
  }

  .seen__readmore a {
    font-size: 20px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps {
    left: -154px;
    bottom: -100px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps img {
    max-width: 72%;
  }

  .get__started__boxes__box.get__started__downloadmobile {
    margin-top: 100px;
  }

  .get__started__boxes {
    padding-top: 170px;
    padding-bottom: 180px;
  }

  .footerbg .upper__footer {
    margin-bottom: 130px;
  }

  .footerbg .container {
    max-width: 1140px;
  }

  .footer__logo__content p {
    margin-top: 70px;
    font-size: 27px;
    line-height: 1.8;
  }

  .footer__logo__content img {
    max-width: 65%;
  }

  .footer__contact p {
    font-size: 26px;
    line-height: 1.8;
    margin-bottom: 45px;
    margin-top: 55px;
  }

  .footer__contact>a {
    font-size: 30px;
  }

  .footer__social__link ul li a {
    font-size: 60px;
  }

  .footer__link ul li a {
    font-size: 24px;
  }

  .footer__link ul li {
    margin-bottom: 28px;
  }

  .security__token__banner__box {
    padding: 80px 80px 60px;
  }

  .wallet__main__inner {
    max-width: calc(100% - 200px);
  }

  .asset__tokenization__partners__partner {
    width: 240px;
    height: 79px;
  }

  .dashboard__wallet .wallet__main__inner {
    max-width: calc(100% - 200px);
  }

  .login__screen__content {
    padding: 50px 0;
  }
}

@media (max-width: 1440px) {

  .mobile__feature__wrapper,
  .our__partners__inner,
  .get__started__boxes__inner,
  .cryptox__feature__main__inner,
  .how__it__work__inner,
  .seen__on__media__inner,
  .footerbg .container {
    max-width: calc(100% - 200px);
  }

  .portfolio__sto__box {
    margin: 0 15px;
    padding: 35px 30px;
  }

  .portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 400px);
  }

  .portfolio__sto__box__feature h4 {
    text-align: center;
    font-size: 22px;
  }

  .portfolio__sto__box__feature span {
    font-size: 12px;
    line-height: 1.5;
  }

  .sto__features .app__feature__steps__box__img {
    width: calc(100% - 495px);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .sto__faster__simpler__detail__options__option {
    padding: 110px 25px 25px;
  }

  .sto__faster__simpler__detail__options__option__img {
    width: 150px;
    height: 150px;
  }

  .big__right__circle,
  .sto__circles .big__right__one__circle {
    right: 0;
    max-width: 500px;
  }

  .right__circle,
  .right__one__circle {
    right: 0;
  }

  .left__circle,
  .left__one__circle {
    left: 0;
  }

  .big__left__circle,
  .sto__circles .big__left__one__circle {
    max-width: 500px;
    left: 0;
  }

  .login__screen__content .container {
    max-width: calc(100% - 200px);
  }

  .login__screen__content h1 {
    text-align: left;
    font-size: 58px;
  }

  .investor__portal__welcome .security__token__banner__box {
    border-radius: 185px;
    margin-top: 120px;
  }

  .cryptoscdec__header {
    padding-top: 180px;
  }

  .wallet__main__accounts ul li .currency__info p {
    margin-bottom: 0;
    margin-right: 18px;
    font-size: 20px;
  }

  .wallet__main__accounts ul li .currency__btn {
    min-width: auto;
    margin-right: 8px;
  }

  .wallet__main__accounts ul li .currency__info {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }

  .wallet__main__accounts ul li .currency__btn a {
    font-size: 14px;
  }

  .wallet__main__accounts ul li .currency__info p {
    font-size: 17px;
  }

  .profile__kyc__tabs .nav-link {
    font-size: 17px;
    max-width: 114px;
    margin: 0px 12px;
  }

  .digital__assets__cards__inner {
    max-width: calc(100% - 200px);
  }

  .asset__tokenization__partners__partner {
    margin: auto;
  }

  .ast__banner .ast__img {
    text-align: center;
    margin-bottom: -65px;
  }

  .asset__tokenization__f.portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 200px);
  }

  .dashboard__wrapper {
    max-width: calc(100% - 200px);
  }

  .activity__history__filter {
    display: block;
  }

  .activity__history__filter__products {
    margin: 0 auto 12px;
  }

  .activity__history__filter__dates {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .activity__btn--clear-filters {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1200px) {
  .banner__main__inner {
    max-width: calc(100% - 150px);
  }

  .cryptolist__main {
    max-width: 630px;
  }

  h1 {
    font-size: 52px;
  }

  .banner__main__inner p {
    max-width: 600px;
  }

  .theme-btn {
    font-size: 22px;
    line-height: 37px;
    padding: 14px 48px;
  }

  .banner__main__inner p {
    max-width: 600px;
    margin: auto;
  }

  .big__right__circle {
    max-width: 46%;
  }

  .cyrptolist {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .coin__img {
    width: 60px;
  }

  .cryptolist__main .coin__box .coin__short {
    min-width: auto;
  }

  .cryptolist__main .coin__box p {
    font-size: 18px;
  }

  .get__started__boxes__inner,
  .cryptox__feature__main__inner,
  .how__it__work__inner,
  .seen__on__media__inner {
    max-width: 860px;
  }

  .big__left__circle {
    max-width: 46%;
  }

  .right__circle {
    right: -50px;
    top: 48.5%;
    z-index: 0;
    max-width: 44%;
  }

  .left__circle {
    left: -50px;
    top: 58%;
    z-index: 0;
    max-width: 44%;
  }

  .right__one__circle {
    right: -50px;
    top: 66%;
    z-index: 0;
    max-width: 44%;
  }

  /* .circles__object {
    display: none;
  } */
  .cryptoimage {
    height: 319px;
    margin-bottom: 65px;
    min-height: auto;
  }

  .cryptoimage img {
    max-width: 80%;
  }

  .cryptox__feature__main .cryptox__feature__box {
    min-height: 675px;
    max-width: 405px;
    padding: 25px;
  }

  h3 {
    font-size: 32px;
  }

  .cryptox__feature__box p {
    font-size: 18px;
  }

  .cryptox__feature__box ul li {
    font-size: 18px;
  }

  .cryptolist__main .coin__box .theme-btn {
    padding: 9px 35px;
  }

  .powered__main img {
    max-width: 70%;
  }

  p {
    font-size: 20px;
  }

  .crypto__boxes__box {
    padding: 35px;
  }

  .crypto__boxes__box h3 {
    margin-bottom: 18px;
  }

  .process__use__inner {
    max-width: 715px;
  }

  h2 {
    font-size: 48px;
  }

  .sidebar__main {
    height: 100%;
    width: 0;
  }

  .brand__logo img {
    max-width: 70%;
  }

  .sidebar__main .mainlinks__sidebar>a img,
  .sidebar__main .mainlinks__sidebar>a i,
  .sidebar__main .helplinks__sidebar>a img,
  .sidebar__main .helplinks__sidebar>a i {
    margin-right: 32px;
    font-size: 23px;
    max-width: 30px;
  }

  .sidebar__main .mainlinks__sidebar>a,
  .sidebar__main .helplinks__sidebar>a {
    padding: 0px 23px;
  }

  .sidebar__main .brand__logo {
    margin-bottom: 45px;
  }

  .sidebar__main.right__sidebar .mainlinks__sidebar>a {
    padding: 0px 20px;
  }

  .sidebar__main.right__sidebar .account__icon {
    margin-right: 15px;
  }

  .sidebar__main.right__sidebar .account__icon a {
    color: #fff;
    font-size: 42px;
  }

  .howwork__box .howwork__img {
    min-width: 145px;
  }

  .howwork__box .howwork__img img {
    max-width: 120px;
  }

  .seen__on__media__header {
    padding-right: 0;
    text-align: center;
  }

  .img__short__action {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .media__cat p {
    margin-bottom: 0;
    font-size: 15px;
  }

  .media__cat {
    padding: 7px 15px;
    min-width: 145px;
    bottom: -23px;
  }

  .seen__on__media__box .seen__title h4 {
    font-size: 16px;
    min-height: 78px;
  }

  .seen__readmore a {
    font-size: 16px;
  }

  .seen__readmore {
    text-align: right;
    padding-top: 15px;
  }

  .get__started__boxes__box .get__started__btn .theme-btn {
    display: inline-block;
    padding: 16px 32px;
  }

  .get__started__boxes__box {
    padding: 35px 20px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps {
    left: -258px;
    bottom: -81px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps img {
    max-width: 50%;
  }

  .footerbg .container {
    max-width: 860px;
  }

  .mobile__header {
    text-align: center;
  }

  .cryptocurrent__header {
    width: 100%;
  }

  .portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 200px);
  }

  .sto__faster__simpler__detail__options__option h4 {
    margin-bottom: 28px;
    font-size: 18px;
    min-height: 44px;
  }

  .sto__faster__simpler__detail__options__option p {
    min-height: 162px;
  }

  .signup__now__bar {
    padding: 40px;
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .signup__now__bar h3 {
    margin-left: 40px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .signup__now__bar .theme-btn {
    padding: 20px 28px;
    font-size: 24px;
  }

  .security__token__banner__box {
    border-radius: 110px;
  }

  .sto__purchase___form__gap {
    margin-top: 35px;
  }

  .investor__portal__welcome .security__token__banner__box {
    border-radius: 150px;
  }

  .wallet__main__accounts {
    max-width: 500px;
    margin: 0 auto 50px;
  }

  .profile__kyc__tabs .nav-pills {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .profile__kyc__tabs .nav-link {
    font-size: 17px;
    max-width: 114px;
    margin: 16px 12px;
  }

  .kyc__users__user {
    position: relative;
    padding: 55px 15px 15px;
  }

  .kyc__users__user .theme__list {
    padding-top: 16px;
    padding-bottom: 39px;
    min-height: 289px;
  }

  .kyc__users__user h4 {
    text-align: center;
    font-size: 19px;
  }

  .profile__view__box {
    padding: 25px;
  }

  .app__features__boxes__box {
    min-height: 223px;
  }

  .mobile__app__banner__box__img {
    position: static;
    text-align: center;
    margin-top: -325px;
    margin-bottom: 30px;
  }

  .mobile__app__banner__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .app__features {
    padding-top: 180px;
  }

  .security__token__banner__inner {
    padding-bottom: 40px;
  }

  .competition__boxes__box .cryptoimage {
    height: auto;
  }

  .competition__boxes {
    border-radius: 70px;
  }

  .converts__main {
    padding: 80px 0;
  }

  .convert__currency__detail p {
    font-size: 20px;
  }

  .convert__currency__detail span {
    font-size: 16px;
  }

  .instant__order span {
    font-size: 22px;
  }

  .creditcard__screen {
    padding: 100px 0 76px;
  }

  .activity__history__filter__dates__date__max .form-control {
    font-size: 17px;
    line-height: 28px;
    padding: 13px 26px;
  }

  .activity__history__filter__dates__date span {
    color: #F5FBFF;
    font-size: 16px;
    line-height: 28px;
    margin-right: 10px;
  }

  .activity__history__filter__dates__btn .theme-btn {
    font-size: 18px;
    line-height: 37px;
    padding: 14px 36px;
  }

  .product__balance__intro h4 {
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 0;
  }

  .sidebar__main .brand__logo a {
    display: none !important;
  }

  .sidebar__main .brand__logo a.fullicon {
    display: block !important;
  }

  .activity__from__dpk-label {
    margin-left: 0;
  }

  .activity__select-wrapper {
    margin-right: 50%;
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 1024px) {


  .send-receive {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .send-receive__main {
    overflow: unset;
    min-width: unset !important;
  }

  .send-receive-tabs {
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }

  .send-form__source {
    flex-direction: row;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .send-address__form-body .ap-input__input-box {
    width: auto;
  }

  .send-form__input-box {
    display: flex;
    flex-direction: column;
    width: inherit;
  }

  .send-receive__send-side-details {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }

  .send-form__source-item,
  .receive__source-item {
    line-height: 1.5rem;
    padding: 1rem 0.8rem;
    height: auto;
    width: auto;
  }

  .sidepane-details__information--top {
    margin-top: 0;
    width: fit-content;
  }
}

@media (max-width: 991px) {
  h1 {
    font-size: 43px;
  }

  .banner__main__inner h1 {
    margin: 45px auto 25px;
  }

  .banner__main__inner>img {
    max-width: 44%;
  }

  .banner__main__inner p {
    max-width: 550px;
  }

  .cryptolist__main {
    max-width: 580px;
  }

  .cryptolist__main .coin__box p {
    font-size: 16px;
  }

  .cryptolist__main .coin__box .theme-btn {
    padding: 8px 33px;
    font-size: 18px;
  }

  .cryptox__feature__main .cryptox__feature__box {
    min-height: auto;
    max-width: 405px;
    padding: 25px;
    margin: 0 auto 50px;
  }

  .cryptoimage {
    height: auto;
    max-width: 450px;
    margin: 0 auto -25px;
  }

  .cryptoimage img {
    max-width: 100%;
  }

  .powered__main {
    margin: 80px 0;
  }

  .crypto__boxes__box {
    max-width: 500px;
    margin: 0 auto 30px;
  }

  .crypto__boxes__box h3 {
    min-height: auto;
  }

  .crypto__boxes__box p {
    min-height: auto;
  }

  .cryptox__feature__main {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .process__use {
    padding-top: 80px;
  }

  .process__use__inner {
    max-width: 550px;
  }

  .process__use__inner .ceo__text h5 {
    font-size: 24px;
  }

  .how__it__work__inner {
    max-width: 550px;
  }

  .howitwork__text {
    text-align: center;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 28px;
  }

  .howwork__box .howwork__img {
    min-width: 180px;
  }

  .howwork__box .howwork__img img {
    max-width: 155px;
  }

  .seen__on__media {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .seen__on__media__header {
    text-align: center;
    margin-bottom: 50px;
  }

  .seen__on__media__box {
    padding: 26px;
    max-width: 480px;
    margin: 0 auto 30px;
  }

  .img__short__action {
    position: absolute;
    top: 30px;
    left: 15px;
  }

  .media__cat {
    bottom: -20px;
  }

  .seen__on__media__box .seen__title h4 {
    font-size: 26px;
    min-height: auto;
  }

  .seen__readmore a {
    font-size: 18px;
  }

  .get__started__boxes {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .get__started__boxes__box {
    max-width: 500px;
    margin: 0 auto 50px;
  }

  .video__box {
    margin-bottom: 35px;
    border-radius: 20px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content {
    text-align: left;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .mobile__forapps {
    position: static;
    text-align: center;
  }

  .get__started__boxes__box.get__started__downloadmobile {
    margin-top: 0;
  }

  .get__started__boxes__box .get__started__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .theme-btn {
    margin: 0 5px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .theme-btn span {
    margin-right: 10px;
  }

  .footer__logo__content {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }

  .footer__contact {
    max-width: 500px;
    margin: 50px auto 0;
    text-align: center;
  }

  .footer__social__link ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer__link {
    margin-bottom: 28px;
  }

  .bottom__footer {
    max-width: 500px;
    margin: auto;
  }

  .security__token__banner__inner {
    padding-bottom: 40px;
  }

  .security__token__banner__box {
    padding: 65px;
    border-radius: 90px;
  }

  .security__token__banner__box p {
    margin-bottom: 35px;
  }

  .security__token__banner__box .theme-btn {
    min-width: 300px;
    margin: 8px 0;
  }

  .portfolio__sto {
    padding: 80px 0 0;
  }

  .sto__features .app__feature__steps__box__img {
    position: static;
    -webkit-transform: none;
    transform: none;
    margin: 30px 0;
    width: 100%;
  }

  .sto__features .app__feature__steps__box {
    margin: 80px 0;
  }

  .sto__faster__simpler__detail__options__option {
    padding: 100px 25px 25px;
    margin-bottom: 110px;
  }

  .sto__faster__simpler__detail__options__option h4,
  .sto__faster__simpler__detail__options__option p {
    min-height: auto;
  }

  .our__partners .theme__h3__heading {
    margin-bottom: 80px;
  }

  .our__partners__partner {
    padding: 30px;
  }

  .our__partners {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .trading__simpler__faster {
    text-align: center;
    padding: 80px 0 50px;
  }

  .sto__purchase___form {
    padding-top: 149px;
    padding-bottom: 80px;
  }

  .login__screen__content h1 {
    font-size: 44px;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .investor__portal__signup__main {
    padding-top: 190px;
    padding-bottom: 80px;
  }

  .investor__portal__welcome .security__token__banner__box {
    border-radius: 90px;
  }

  .cryptoscdec__header .nav-pills .nav-item .nav-link {
    font-size: 18px;
    line-height: 28px;
    padding: 14px 30px;
  }

  .kyc__users__user .theme__list {
    min-height: auto;
  }

  .kyc__users__user {
    max-width: 345px;
    margin: 0 auto 56px;
  }

  .profile__kyc__content__intro__btns {
    margin-bottom: 40px;
  }

  .current__tag__update {
    padding: 26px 41px;
  }

  .update__current__tag .form-control {
    max-width: 120px;
  }

  .portable__table .norecord {
    padding: 26px 41px;
  }

  .portal__tradeview {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .order__book__box {
    margin-bottom: 16px;
    height: auto;
  }

  .order__entries {
    margin-bottom: 16px;
    height: auto;
  }

  .app__feature__steps__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .app__feature__steps__box__img {
    position: static;
    text-align: center;
    margin-top: -250px;
    margin-bottom: 30px;
  }

  .app__feature__steps__inner .app__feature__steps__box:nth-child(even) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .app__feature__steps__inner .app__feature__steps__box:nth-last-child() {
    margin-bottom: 80px;
  }

  .app__feature__steps__inner .app__feature__steps__box:nth-child(even) .app__feature__steps__box__text {
    margin-left: 0;
  }

  .app__feature__steps__box__text {
    max-width: 100%;
  }

  .app__feature__steps {
    padding-top: 37px;
    padding-bottom: 80px;
  }

  .tradeview__graph {
    margin-bottom: 30px;
  }

  .digital__securties .sto__faster__simpler__detail__options__option h4 {
    min-height: auto;
  }

  .digital__securties .sto__faster__simpler__detail {
    padding-bottom: 0;
  }

  .signup__now__bar__content {
    text-align: center;
    padding-left: 0;
  }

  .ast__banner .ast__img {
    text-align: center;
    margin-bottom: -32px;
  }

  .ast__compitetion__boxes {
    margin-top: -75px;
  }

  .competition__boxes__box .cryptoimage {
    height: auto;
    max-width: 100%;
  }

  .digital__securties .theme__h3__heading {
    margin-bottom: 100px;
  }

  .request__demo__main__form .login__form__main {
    padding: 40px;
  }

  .dashboard__header__box h4 {
    margin-bottom: 0;
    font-size: 23px;
  }

  .dashboard__market__overview {
    padding: 35px;
  }

  .dashboard__market__overview__currency ul li a {
    font-size: 14px;
  }

  .convert__currency__detail {
    margin-top: 30px;
  }

  .activity__history__filter__dates {
    display: block;
    text-align: center;
  }

  .activity__history__filter__dates__date {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .activity__history__filter__dates__btn {
    margin-top: 15px;
  }

  .product__balance__margin__values {
    margin: 10px 0;
  }

  .activity__triangles-container {
    right: -12%;
  }
}

@media (max-width: 767.99px) {
  h1 {
    font-size: 32px;
  }

  .banner__main__inner>img {
    max-width: 60%;
  }

  .banner__btns .theme-btn {
    min-width: 275px;
  }

  .banner__main__inner .banner__btns .theme-btn {
    margin: 0 0 11px 0;
  }

  .cryptolist__main {
    max-width: calc(100% - 150px);
  }

  .cryptolist__main .coin__box {
    display: block;
    text-align: center;
  }

  .coin__img {
    margin: 0 auto 20px;
  }

  .cryptolist__main .coin__box .coin__title,
  .cryptolist__main .coin__box .coin__short,
  .cryptolist__main .coin__box .coin__price {
    text-align: center;
    min-width: auto;
  }

  .cryptolist__main .coin__box p {
    font-size: 18px;
  }

  .coin__title,
  .coin__price {
    width: 50%;
    float: left;
  }

  .cryptolist__main .coin__box .theme-btn {
    display: block;
    max-width: 50%;
    margin: 10px auto 0;
  }

  .howwork__box {
    display: block;
    text-align: center;
  }

  .howwork__box .howwork__img {
    margin-bottom: 30px;
    min-width: auto;
    margin-right: 0;
  }

  .cryptox__feature__main .cryptox__feature__box,
  .cryptoimage,
  .crypto__boxes__box,
  .process__use__inner,
  .how__it__work__inner,
  .seen__on__media__box,
  .get__started__boxes__box,
  .footerbg .container {
    max-width: calc(100% - 150px);
  }

  h2 {
    font-size: 36px;
  }

  .get__started__boxes__box .get__started__btn .theme-btn {
    padding: 12px 25px;
    font-size: 20px;
  }

  .get__started__boxes__box.get__started__downloadmobile .get__started__content .theme-btn {
    display: block;
    margin-bottom: 11px;
  }

  .get__started__boxes__box .get__started__btn {
    display: block;
  }

  .security__token__banner__box {
    padding: 50px;
    border-radius: 55px;
  }

  .cryptocurrent__header .nav-pills .nav-item .nav-link {
    font-size: 18px;
    line-height: 1.5;
    padding: 14px 22px;
  }

  .portfolio__sto h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 155px);
  }

  .mobile__feature__wrapper,
  .our__partners__inner,
  .get__started__boxes__inner,
  .cryptox__feature__main__inner,
  .how__it__work__inner,
  .seen__on__media__inner,
  .footerbg .container {
    max-width: calc(100% - 120px);
  }

  .trading__process__steps__step .theme-btn {
    font-size: 19px;
    line-height: 1.8;
  }

  .trading__process__steps__step__count p {
    font-size: 24px;
  }

  .sto__faster__simpler__detail .img-center {
    padding: 80px 0 135px;
  }

  .sto__purchase___form {
    padding-top: 205px;
  }

  .signup__now__bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    text-align: center;
  }

  .signup__now__bar h3 {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .sto__purchase___form__btn .theme-btn {
    font-size: 20px;
  }

  .login__screen__content .container {
    max-width: calc(100% - 150px);
  }

  .wallet__main__inner {
    max-width: calc(100% - 150px);
  }

  .cryptoscdec__header .nav-pills .nav-item {
    margin-bottom: 20px;
  }

  .wallet__main__accounts ul {
    padding: 0;
  }

  .wallet__main__accounts h5 {
    font-size: 22px;
  }

  .wallet__main__accounts ul li {
    display: block;
  }

  .wallet__main__accounts ul li .currency__img {
    margin: 0 auto 10px;
  }

  .wallet__main__accounts ul li .currency__info {
    display: block;
  }

  .wallet__main__accounts ul li .currency__info p {
    text-align: center;
    margin-right: 0;
    margin-bottom: 18px;
  }

  .wallet__main__accounts ul li .currency__btn {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .portable__table table thead tr th:first-of-type {
    padding-left: 18px;
  }

  .portable__table table tbody tr td:first-of-type {
    padding-left: 18px;
  }

  .trade__reports h5 {
    padding-left: 30px;
    font-size: 20px;
  }

  .profile__kyc__content__intro h5 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 26px;
  }

  .current__tag__update {
    display: block;
  }

  .update__current__tag {
    margin-top: 20px;
  }

  .mobile__app__banner__box {
    padding: 40px 30px 40px;
  }

  .mobile__app__banner__btns .theme-btn {
    margin: 10px 0;
    display: block;
  }

  .app__features {
    padding-top: 80px;
  }

  .digital__assets__cards__inner {
    max-width: calc(100% - 150px);
  }

  .digital__assets__cards__card__title h4 {
    font-size: 24px;
  }

  .digital__assets__cards__card__detail span {
    font-size: 16px;
  }

  .digital__assets__cards__card__detail h4 {
    margin-bottom: 0;
    font-size: 18px;
  }

  .accreditation__steps__card {
    padding: 78px 35px 35px;
  }

  .competition__boxes__box .cryptox__feature__box {
    padding: 47px 30px;
    margin: 0 auto 80px;
  }

  .dashboard__wrapper {
    max-width: calc(100% - 150px);
  }

  .dashboard__header__box h4 {
    margin-bottom: 0;
    font-size: 17px;
    line-height: 1.5;
  }

  .dashboard__market__overview__currency ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .dashboard__market__overview__currency ul li {
    margin: 10px 15px;
  }

  .portable__table {
    width: 100%;
    overflow-x: auto;
  }

  .recent__activity__main__header h4 {
    font-size: 20px;
  }

  .instant__order .form-select {
    font-size: 16px;
  }

  .activity__history__filter__dates__date {
    display: block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .activity__history__filter__dates__date__max {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 13px 0;
  }

  .activity__history__filter__dates__date span {
    width: 94px;
    text-align: left;
  }

  .activity__triangles-container {
    right: 1rem
  }
}

@media (max-width: 576px) {

  .banner__main__inner,
  .cryptox__feature__main .cryptox__feature__box,
  .cryptoimage,
  .crypto__boxes__box,
  .process__use__inner,
  .how__it__work__inner,
  .seen__on__media__box,
  .get__started__boxes__box,
  .footerbg .container,
  .cryptolist__main {
    max-width: 100%;
  }

  .mobile__header {
    max-width: 100%;
  }

  .theme-btn {
    font-size: 18px;
    line-height: 37px;
    padding: 12px 27px;
  }

  .powered__main img {
    max-width: 100%;
  }

  .cryptocurrent__header {
    width: calc(100% - 30px);
  }

  .cryptocurrent__header .nav-item {
    width: 100%;
  }

  .cryptocurrent__header .nav-pills .nav-item .nav-link {
    width: 100%;
  }

  .security__token__banner__box .theme-btn {
    min-width: 100%;
  }

  .security__token__banner__box {
    padding: 30px;
    border-radius: 40px;
  }

  .mobile__feature__wrapper,
  .our__partners__inner,
  .get__started__boxes__inner,
  .cryptox__feature__main__inner,
  .how__it__work__inner,
  .seen__on__media__inner,
  .footerbg .container {
    max-width: calc(100% - 30px);
  }

  .security__token__banner__box p {
    margin-bottom: 25px;
  }

  .cryptocurrent__header .nav-pills {
    border-radius: 30px;
  }

  .security__token__banner__inner {
    padding-bottom: 25px;
  }

  .portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 30px);
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .portfolio__sto__box__media__category {
    padding: 15px 45px;
  }

  h4 {
    font-size: 24px;
  }

  .portfolio__sto__box__content p {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .portfolio__sto__box__feature span {
    min-height: 36px;
  }

  .portfolio__sto__box__btn .theme-btn {
    display: block;
  }

  .trading__process__steps {
    border-radius: 45px;
  }

  .trading__process__steps__step__text {
    padding: 0 25px 24px;
  }

  .trading__process__steps__step .theme-btn {
    font-size: 17px;
  }

  .trading__process__steps__step__count p {
    font-size: 22px;
  }

  .trading__process__steps__step__count {
    top: -26px;
    padding: 14px 24px;
  }

  .signup__now__bar .theme-btn {
    padding: 12px 28px;
    font-size: 20px;
  }

  .form-control {
    font-size: 18px;
  }

  .signup__now__bar {
    padding: 30px;
  }

  .login__screen__content .container {
    max-width: calc(100% - 30px);
  }

  .login__screen__content h1 {
    font-size: 35px;
  }

  .login__form__main {
    padding: 50px 30px;
  }

  .login__form__main button {
    width: 100%;
  }

  .footerbg {
    padding: 80px 0;
  }

  .footer__logo__content p {
    margin-top: 70px;
    font-size: 22px;
  }

  .footer__contact p {
    font-size: 22px;
  }

  .footer__contact>a {
    font-size: 25px;
  }

  .footer__social__link ul li a {
    font-size: 50px;
  }

  .footer__link ul li a {
    font-size: 22px;
  }

  .cant__login ul li a {
    font-size: 16px;
  }

  .field-icon {
    margin-top: -35px;
    font-size: 18px;
    right: 13px;
  }

  .profile__kyc__content__intro {
    padding: 30px 21px;
  }

  .profile__kyc__content__intro h5 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .profile__kyc__content__intro p {
    font-size: 16px;
    line-height: 1.5;
  }

  .profile__kyc__content__intro__btns .theme-btn {
    font-size: 16px;
    line-height: 28px;
    padding: 11px 19px;
  }

  .investor__portal__welcome .security__token__banner__box {
    border-radius: 60px;
  }

  .wallet__main__inner {
    max-width: calc(100% - 30px);
  }

  .current__tag__update {
    padding: 26px 21px;
  }

  .profile__kyc__content__intro p {
    font-size: 16px;
    line-height: 1.5;
  }

  .api__keys__box .apikeys__box {
    display: block;
  }

  .api__keys__text {
    margin-bottom: 30px;
  }

  .portable__table {
    width: 100%;
    overflow-x: auto;
  }

  .checkbox label {
    font-size: 16px;
  }

  .mobile__app__banner__box__img {
    margin-top: -280px;
  }

  .app__features__header {
    text-align: center;
    margin-bottom: 80px;
  }

  .app__features__boxes__box {
    text-align: center;
    min-height: auto;
    display: block;
  }

  .app__features__boxes__box__icon {
    margin: 0 auto 30px;
  }

  .app__feature__steps__box {
    padding: 26px 30px 30px;
  }

  .app__feature__steps__appsc ul {
    display: block;
  }

  .app__feature__steps__appsc ul li {
    margin: 10px 0;
  }

  .app__feature__steps__appsc ul li .theme-btn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .digital__assets__cards__inner {
    max-width: calc(100% - 15px);
  }

  .digital__assets__cards__card__title h4 {
    font-size: 21px;
  }

  .digital__assets__cards__card__detail h4 {
    font-size: 14px;
    line-height: 1.5;
  }

  .digital__assets__cards__card__detail span {
    font-size: 14px;
  }

  .digital__assets__cards__card__category h4 {
    margin-bottom: 0;
    font-size: 18px;
  }

  .digital__assets__cards__card__category {
    padding: 14px 28px;
    top: -24px;
  }

  .digital__assets__heading {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .accreditation__steps__card {
    padding: 50px 30px 30px;
  }

  .accreditation__steps__card__points.requirements__points {
    padding-left: 0;
  }

  .accreditation__steps__card h3 {
    text-align: center;
    margin-bottom: 25px;
  }

  .accreditation__steps__card__points h4 {
    text-align: center;
    font-size: 21px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .theme__list li {
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
  }

  .buy__n__sell__order__detail__btn .theme-btn {
    padding: 16px 20px;
  }

  .asset__tokenization__partners__partner {
    width: 170px;
    height: 62px;
  }

  .asset__tokenization__partners {
    padding-bottom: 80px;
  }

  .competition__boxes {
    border-radius: 45px;
  }

  .competition__boxes__box .cryptox__feature__box ul li {
    line-height: 1.5;
  }

  .sto__launch {
    padding: 50px 30px 50px;
  }

  .sto__launch .theme-btn {
    width: 100%;
  }

  .asset__tokenization__f.portfolio__sto .mobile__feature__wrapper {
    max-width: calc(100% - 0px);
  }

  .request__demo__main__form .login__form__main {
    padding: 40px 30px;
  }

  .dashboard__wrapper {
    max-width: calc(100% - 30px);
  }

  .market__overflow__currency__title h5 {
    color: #F5FBFF;
    /* font-family: "Now-Bold"; */
    font-family: "DM Sans";
    font-size: 22px;
  }

  .market__overflow__currency__title p {
    font-size: 13px;
    line-height: 1.5;
  }

  .market__overflow__currency__values p {
    color: #F5FBFF;
    font-size: 14px;
  }

  .dashboard__market__overview h4 {
    font-size: 14px;
  }

  .recent__activity__main__header h4 {
    font-size: 16px;
  }

  .balance__box ul li p {
    font-size: 18px;
  }

  .dashboard__market__overview h4 {
    margin-bottom: 10px;
  }

  .dashboard__market__overview,
  .recent__activity__main {
    padding: 25px;
  }

  .instant__order__btns a {
    margin: 0 4px;
    min-width: 110px;
  }

  .instant__order__btns {
    padding: 30px 0 25px;
  }

  .instant__order span {
    margin-bottom: 30px;
  }

  .instant__order .form-select {
    padding: 15px 50px 15px 25px;
  }

  .instant__order__ammount__text {
    text-align: right;
  }

  .instant__order__btn .theme-btn {
    color: #F5FBFF;
    font-size: 14px;
    line-height: 1.5;
  }

  .credit__buying {
    padding: 50px 30px;
  }

  .credit__buying__field span {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .credit__buying__field .form-select {
    font-size: 16px;
    padding: 13px 30px;
  }

  .credit__view__box__btn a {
    display: block;
    font-size: 16px;
  }

  .product__balance__intro {
    background: #600084 0% 0% no-repeat padding-box;
    border-radius: 33px;
    padding: 40px 25px;
  }

  .asset-activity-details-header__left-container,
  .asset-activity-details-header__right-container,
  .asset-activity-details-header__left-container {
    width: 90%;
    align-items: center;
  }

  .asset-activity-details-header__btn {
    align-items: center;
  }

  .asset-activity-details-header__clear__btn {
    width: 100% !important;
  }

  .asset-activity-details-header__from__dpk-wrapper,
  .asset-activity-details-header__to__dpk-wrapper {
    margin-bottom: 2rem !important;
    position: relative;
    width: auto;
  }

  .activity__triangles-container {
    right: -15%;
  }

  .activity__select-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .activity__btn--clear-filters {
    margin-left: unset;
    align-self: auto !important;
    width: 100%;
  }

  .activity__from__dpk-label {
    margin-left: 0 !important;
  }

  .activity__from__dpk-wrapper {
    margin-bottom: 1rem !important;
  }

  .activity__select-wrapper {
    width: auto;
  }

  .transfers__primary-text {
    font-size: 1.4rem;
  }

  .wallet-details__wallet-nav-items {
    justify-content: flex-end;
    align-items: baseline;
    gap: 2rem;
  }
}

/* Button style */
.ap-button__btn {
  font-size: 1.4rem!important;
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  padding: 0 1rem;
  border: 1px solid transparent;
  color: var(--font__color-primary);
  white-space: nowrap;
  padding: .8rem 1.25rem!important;
  background: var(--color-secondary)!important;
  border-radius: 90px;
  height: auto;
  line-height: normal;
  /* display: inline-block; */
  position: relative;
  overflow: hidden;
}
.ap-button__btn:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.ap-button__btn:hover:after {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}
.ap-button__btn--additive {
  background: var(--success__bg-color);
}
.ap-button__btn--subtractive {
  background: var(--danger__bg-color)!important;
}

.bordered-button-wrapper {
  border: 1px solid #2AA6A6;
  background: transparent !important;
  border-radius: 30px;
}
.bordered-button-wrapper .bordered-button-content {
  background:#2AA6A6 !important;
  margin: 4px;
  border-radius: 30px;
  padding: 1rem .5rem;
  font-weight: bold!important;
  position: relative;
  overflow: hidden;
}
.bordered-button-wrapper .bordered-button-content:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.bordered-button-wrapper .bordered-button-content:hover:after {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}


.order-entry__btn--additive {
  background: var(--exchange-colors__buy)!important;
}
.advanced-order-form__verification-required .verification-required__container {
  max-width: 35rem;
  margin-top: 2rem;
}
.advanced-order-form__btn--additive {
  background: var(--exchange-colors__buy)!important;
}

.forgot-password-form__btn {
  font-size: 2rem!important;
  padding: 1.5rem!important;
}
/*# sourceMappingURL=style.css.map */