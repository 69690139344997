.slide-pane {
  max-width: 65rem;
}

.slide-pane__header {
  flex: 0 0 13rem;
  padding-top: 2rem;
  border-bottom: none;
  background: var(--side-pane__header-bg-color);
}

.slide-pane__content {
  position: static;
  height: 100%;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: var(--side-pane__content-bg-color);
}

.slide-pane__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 1rem 3rem 3rem 3rem;
  margin-top: 2rem;
  height: 100%;
}

.slide-pane__close {
  display: none;
}

.ap-sidepane__close-button {
  top: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  padding: 0.75rem 1.5rem;
  border-radius: 0px 0px 0px 4px;
}

.ap-sidepane__close-button:hover {
  opacity: 0.8;
}

.slide-pane__title-wrapper {
  margin-right: 3rem;
  margin-bottom: 1rem;
}

/* by using this selector, we avoid importat tag for font */

.slide-pane .slide-pane__title {
  font-size: 2rem;
  color: var(--font__color-primary);
}

.ap-tab__menu--side-pane {
  background: var(--primary__bg-color);
  padding: 0 3rem;
}

.ap-tab__menu-item--side-pane {
  height: 36px;
  padding: 0 23px;
  font-size: 1.1rem;
  position: relative;
  border-bottom: 2px solid transparent;
}

.ap-tab__menu-item--side-pane-active {
  border-bottom: 2px solid var(--side-pane__border-color--active);
}

.ReactModalPortal {
  position: fixed;
  z-index: 20;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.slide-pane__overlay.ReactModal__Overlay {
  background: transparent;
  z-index: var(--z-index__side-pane);
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background: var(--modal__overlay-color);
}

.slide-pane__overlay.ReactModal__Overlay--before-close {
  background: transparent;
}

.retail-sidepane__container {
  max-width: 54rem;
}

.retail-sidepane-with-details__container {
  max-width: 88.8rem;
  width: 100%;
}

.retail-sidepane-with-details__close-button {
  top: 1.75rem;
}

.retail-api-keys-sidepane__close-button {
  padding: 1.9rem;
  color: var(--font__color-secondary-light);
}

.retail-api-keys-sidepane__close-button:hover {
  color: var(--font__color-primary);
}

.slide-pane__hide-header {
  display: none;
}

.reports-sidepane__container {
  width: 54.4rem !important;
}


/* BROWSER QUERIES */

/* IE11+ specific styles */
_:-ms-fullscreen, :root .ap-sidepane__close-button{
  padding-right: 3rem;
}

/* Safari specific styles */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  .retail-sidepane-with-details__container {
    width: auto !important;
  }
}}


/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media (max-width: 576px) {
  .slide-pane_from_right.ReactModal__Content--after-open{
    width:85% !important;
  }

  .slide-pane{
    max-width: unset;
  }

  .slide-pane__content{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .retail-sidepane-with-details__container {
    width: 100%;
  }
  
  .slide-pane__header{
    flex: 0 1 auto;
  }
}