:root {
  --standalone-form__container-width: 46rem !important;
  --standalone-form__header-border-color: rgb(66, 131, 252) !important;
  --success__bg-color: rgb(66, 131, 252) !important;
  --success__color: rgb(66, 162, 252) !important;
  /*--exchange-colors__buy: rgb(21,159,73) !important;
  --exchange-colors__sell: rgb(240,84,53) !important;*/
  /* --link__color: rgb(110, 175, 255) !important; */
  
  /* --component__border-color--glow: rgb(66, 131, 252) !important; */
  --accent-bar__gradient: linear-gradient(260deg, #cc01ff 0%, #00ffdd 100%) 0% 0% no-repeat padding-box !important;
  /* --tab__border-color--selected: rgb(66, 131, 252) !important; */
  
  /* Exchange Page */
  --padded-decimal__units-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__decimals-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__padding-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__separator-buy-color: rgb(26, 201, 90) !important;
  
  --padded-decimal__units-color: black !important;
  --padded-decimal__decimals-color: black !important;
  
  --settings__trade-reports-create-new-actions-btn-bg-color: rgb(66,
  131,
  252) !important;
  --settings__api-keys-create-new-actions-btn-bg-color: rgb(66,
  131,
  252) !important;
  --settings__api-keys-create-new-actions-documentation-btn-font-color: rgb(110,
  175,
  255) !important;
  --side-menu__item-accent-color--selected: rgb(66, 131, 252) !important;
}

/* HEADER LOGO and Signup page logo */
.ap-logo__img {
  width: 400px !important;
  height: auto;
}

.page-header__header-item--logo .ap-logo .ap-logo__img {
  max-width: 20rem !important;
}

.page-header__header-item--logo {
  width: 30rem !important;
}

/* ------------------------------------- Verify Email PAGE CSS ------------------------------------- */
.standalone-modal__wrapper {
  background-size: cover!important;
}

.standalone-modal__container {
  margin-top: 10rem !important;
  background: #18191dcc!important;
  border-radius: 17px !important;
  border: 1px solid #333333 !important;
  opacity: 1;
  /* padding: 40px 50px; */
  text-align: center;
}

.standalone-modal__body {
  background: none !important;
}

.standalone-modal__logo {
  margin: 0px !important;
  margin-top: 4rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0 !important;
  position: relative !important;
}

.standalone-modal__body-text {
  font-size: 1.6rem !important;
}

/* ------------------------------------- GLOBAL LOGIN PAGE CSS ------------------------------------- */

.standalone-layout__logo {
  margin: 0px !important;
  margin-top: 4rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0 !important;
  position: relative !important;
}

.standalone-form__container {
  margin-top: 4rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.standalone-form__header {
  border-bottom: none !important;
  background: #fff !important;
  /* fallback for old browsers */
  padding: 0 !important;
  align-items: center !important;
}

.standalone-form__header-text {
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 2.5rem !important;
  text-align: center !important;
  color: #4283fc !important;
}

/* .ap-icon--close {
  display: none !important;
} */

.standalone-form__form {
  margin: 0 !important;
  padding: 3rem 2.8rem 2.8rem !important;
  border: 1px solid rgb(59, 59, 59) !important;
  border-radius: 5px;
}

.standalone-form__footer {
  background-color: #121212 !important;
}

/* ------------------------------------- LOGIN PAGE CSS ------------------------------------- */
.login-form__input {
  height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: 1px solid rgb(59, 59, 59) !important;
  font-size: 1.6rem !important;
}

.login-form__input:hover {
  border: 1px solid #4283fc !important;
}

.login-form__input:focus {
  border: 3px solid #4283fc !important;
}

.login-form__separator {
  margin: 0rem 0em 1rem 0rem !important;
  border: none !important;
}

.standalone-form__footer {
  background-color: #fff !important;
  text-align: center !important;
  border: 0px solid rgb(59, 59, 59) !important;
}

.standalone-form__success {
  padding: 3rem !important;
  font-size: 1.8rem !important;
  line-height: 1.6 !important;
  border: none !important;
  border-radius: 5px;
}

.login-form__btn {
  height: 4rem !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.ap--label {
  font-size: 1.6rem !important;
  line-height: 1.2 !important;
}

.login-form__link {
  font-size: 1.7rem !important;
  color: white !important;
  font-weight: 1000;
}

.login-form__footer {
  padding: none !important;
  margin: auto !important;
}

/* ------------------------------------- SIGNUP PAGE CSS ------------------------------------- */
.signup-page__container {
  border-radius: 0 !important;
  margin-bottom: 2rem !important;
}

.signup-page__header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.signup-form__container {
  padding: 0 !important;
  border-bottom: none !important;
}

.signup-form__input-wrapper {
  margin-top: 0 !important;
  margin-bottom: 2.8rem !important;
}

.signup-form__input {
  /* height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: 1px solid rgb(59, 59, 59) !important;
  font-size: 1.6rem !important;
  color: #000 !important; */
}

.signup-form__input:hover {
  border: 1px solid #4283fc !important;
}

.signup-form__input:focus {
  border: 3px solid #4283fc !important;
}

.signup-form__footer {
  padding: 1rem 0rem 0rem 0rem !important;
}

.signup-page__footer {
  margin: auto !important;
}

.signup-form__btn {
  height: 4rem !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  color: #fff !important;
}

.signup-form__label {
  line-height: 1.2 !important;
  letter-spacing: -0.5px !important;
}

.signup-page__link {
  font-size: 1.7rem !important;
  color: #4283fc !important;
}

/* --------------------------- Problem Logging In Page -------------------------------*/
.problem-logging-in-page__container {
  border-radius: 0 !important;
  margin-bottom: 2rem !important;
}

.problem-logging-in-page__header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: transparent !important;
}

.problem-logging-in-page__header-text {
  color: white !important;
}

.forgot-password-form__information-text {
  font-size: 2rem !important;
}

.forgot-password-form__form {
  border: none !important;
}

.forgot-password-form__label {
  font-size: 1.8rem !important;
}

.forgot-password-form__input {
  height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: none !important;
  font-size: 1.6rem !important;
  color: #000 !important;
}

.forgot-password-form__input:hover {
  border: 1px solid #d9ff16 !important;
}

.forgot-password-form__input:focus {
  border: 3px solid #d9ff16 !important;
}

.forgot-password-form__separator {
  margin: 0rem 0em 1rem 0rem !important;
  border: none !important;
}

.forgot-password-form__btn--additive {
  background: transparent linear-gradient(104deg, #16ef16bb 0%, #d8ff16b7 100%) 0% 0% no-repeat padding-box !important;
  font-family: "Now" !important;
  color: #ffffff !important;
  border: 0 !important;
}

.problem-logging-in-page__link {
  font-size: 2.5rem !important;
  color: #4283fc !important;
}

/* --------------------------------- NAV BAR --------------------------------------------*/

.user-summary__username-in-display {
  font-size: 1.5rem !important;
  color: #4283fc !important;
}

.user-summary__popover-menu-trigger:hover {
  background-color: var(--nav-item__bg-color--selected) !important;
}

.user-summary__popover-menu-trigger {
  color: #4283fc !important;
}

.user-summary__popover-menu-trigger:focus {
  background-color: var(--nav-item__bg-color--selected) !important;
}

.ap-popover__content {
  background: rgb(41, 43, 48) !important;
}

.ap-popover__content:hover {
  background: var(--nav-item__bg-color--selected) !important;
}

.popover-menu__item {
  color: #4283fc !important;
}

.popover-menu__item:hover,
.popover-menu__item:active,
.popover-menu__item:focus {
  background: var(--nav-item__bg-color--selected) !important;
}

/* -------------------------- PAGE FOOTER --------------------------------------*/
.page-footer__footer-left-content {
  font-size: 1.5rem !important;
  width: 20% !important;
}

.page-footer__footer-right-content {
  width: 80% !important;
}

.page-footer {
  min-height: 23rem !important;
  padding: 4.4rem 12.5rem 0 12.5rem !important;
}

.page-footer a {
  display: block !important;
  font-size: 1.5rem !important;
  line-height: 1.8 !important;
  color: #ffffff !important;
}

.page-footer a:hover {
  color: #e1fa55 !important;
}

.page-footer__footer-left-content {
  color: #ffffff !important;
}

.page-footer__icon {
  padding-top: 10rem !important;
  /* color: #e1fa55 !important; */
  color: #ffffff !important;
}

/* ----------------------------------- Dashboard ----------------------------------------*/
.balance-item__product {
  font-size: 1.5rem !important;
  /* color: black !important; */
}

.balance-item__position {
  /* color: black !important; */
}

/* .activity__action-text {
color: black !important;
}
.activity__amount-quantity {
color: black !important;
}
.activity__status-id {
color: black !important;
}
.activity__date-date {
color: black !important;
} */
/* ---------------------------------------Buy Sell---------------------------------------- */
.buy-sell-make-transaction__title {
  font-size: 2.5rem !important;
  letter-spacing: normal !important;
  /* color: #b81afc !important; */
}

.buy-sell__tab {
  font-size: 2rem !important;
}

.eotc-buy-sell-layout__balances-container {
  padding: 5rem 0 5rem 2rem !important;
}

.buy-sell-balances__lwt-container {
  padding: 1rem 2.7rem 0 0 !important;
}

.buy-sell-balances__title {
  font-size: 1.75rem !important;
  padding-bottom: 1.9rem !important;
}

.buy-sell-order-overview {
  padding: 5rem 0 5rem 2rem !important;
}

.buy-sell-order-overview__title {
  font-size: 1.75rem !important;
  padding-bottom: 1.9rem !important;
}

.buy-sell-order-overview__lwt-container {
  height: 4rem !important;
}

/* ---------------------------------------Exhcange---------------------------------------- */

/* --------- Others -------- */
.ap-tab__menu {
  border-radius: 5px 5px 0 0 !important;
}

/*WALLET + USD DEPOSITS SIDE TAB*/

.fiat-sidepane__main {
  overflow: scroll;
}

.fiat-sidepane__main-form {
  height: fit-content !important;
}

.slide-pane__content {
  background: #fafafa;
}

.send-receive-tabs__tab:first-child {
  border-right: 1px solid rgb(0, 0, 0, 0.12) !important;
}

.send-receive-tabs__tab {
  /* border-bottom: 1px solid rgb(0, 0, 0, 0.12) !important; */
  align-items: end!important;
}

.send-receive__side {
  width: 100% !important;
}

.send-receive__main {
  min-width: 54.5rem !important;
}

/* Track */
.send-receive__main::-webkit-scrollbar-track {
  background: #888888; 
}
.send-receive__main::-webkit-scrollbar-thumb {
  background: #485a5a; 
}

.fiat-tabs {
  color: black !important;
}

/* FAIT SIDE PLANE */
.manual-deposit__title {
  font-size: 2rem !important;
}

.manual-deposit__bank-info-label {
  padding: 5rem 3rem 1rem !important;
  color: white !important;
  font-size: 2rem !important;
}

.manual-deposit__bank-info {
  border: 0px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  padding: 1.5rem 2.3rem 3rem 2.3rem !important;
  margin: 1rem 2rem 1rem !important;
}

.manual-deposit__step-item {
  font-size: 1.4rem !important;
  font-weight: 200 !important;
  line-height: 1.2 !important;
}

.manual-deposit__step {
  font-size: 1.4rem !important;
  margin-top: 1rem !important;
}

.manual-deposit__footer {
  border-top: 0px !important;
}

.retail-sidepane-with-details__container {
  max-width: 100rem !important;
}

.fiat-sidepane__side {
  width: 50rem !important;
}

.fiat-sidepane__main {
  width: 100rem !important;
}

.ap-label-with-text__text {
  font-size: 1.5rem !important;
}

.ap-label-with-text.manual-deposit__lwt-container {
  padding-top: 20px !important;
}

.manual-deposit__lwt-label {
  font-size: 1.2rem !important;
  min-width: 15rem !important;
}

.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  padding-bottom: 0.1rem !important;
}

.sidepane-details__header {
  font-size: 2.6rem !important;
}

.sidepane-details__information-text {
  font-size: 1.4rem !important;
}

.send-address__input {
  color: #0defdf !important;
}

.send-form__input {
  color: #0defdf !important;
}

.receive-form__input {
  color: #0defdf !important;
}

.manual-deposit__input {
  color: #0defdf !important;
}

.fiat-withdraw-form__input {
  color: #0defdf !important;
}

/* FIAT Withdraw Tab */
.fiat-withdraw-form__input-wrapper,
.fiat-withdraw-form__select-wrapper {
  padding-bottom: 0 !important;
}

.ap-password-input__icon {
  z-index: 65535;
}

@media only screen and (max-width: 968px) {
  .instrument-row .instrument-table .instrument-table__row {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  
  .instrument-row {
    margin-top: 2.8rem !important;
  }
}

@media only screen and (max-width: 720px) {
  .page-header-nav__item:not(.page-header-nav__item--selected) {
    background: rgb(41, 43, 48) !important;
  }
  
  .page-footer {
    min-height: 30rem !important;
    padding: 4.4rem 4.4rem 2rem 4.4rem !important;
  }
  
  .page-footer__icon {
    margin-top: 5% !important;
    margin-left: 0% !important;
    padding-top: 10rem !important;
  }
}

@media only screen and (max-width: 480px) {
  .standalone-form__container {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  
  .standalone-layout__logo {
    margin-top: 4rem !important;
  }
  
  .standalone-form__form {
    border: none !important;
    padding: 1.5rem 0rem 2.8rem !important;
  }
  
  .page-footer__footer-left-content {
    width: 100% !important;
    margin-bottom: 5rem !important;
  }
  
  .fiat-sidepane__main {
    width: 100% !important;
    padding: 0 !important;
  }
  
  .fiat-sidepane__side {
    width: 100% !important;
  }
  
  .error-page_container {
    padding: 4rem !important;
  }
  
  .error-page_text-header {
    padding: 0 !important;
  }
  
  .error-page_text-content {
    padding: 0 !important;
  }
}

/* Tradeview page  */
.trading-layout__body {
  border-top: none !important;
}

/* Funded Accounts */

.wallet-card__symbol-label {
  /* color: black !important; */
}

.wallet-card__amount-text {
  /* color: black !important; */
}

.wallet-card__activity-link {
  color: #2aa6a6 !important;
}

/* footer */



/* coin detail  */

.product-details-page__menu {
  /* background: rgba(256, 256, 256, 0.5) !important; */
}

.balances-menu__item-value-symbol {
  /* color: black !important; */
}

/* Transfer request */
.transfer-request-notification-list {
  background: black !important;
}

/* for 2FA */
.ap-auto-tab-input {
  background: white !important;
  caret-color: black !important;
  color: black !important;
}

.ap-padded-decimal {
  color: white !important;
  font-family: Arial !important;
}

.ap-padded-decimal--buy,
.ap-padded-decimal--sell,
.ap-padded-decimal {
  font-family: Arial !important;
  font-size: 1.2rem !important;
}

.ap-padded-decimal__units {
  color: white !important;
}

.ap-padded-decimal__decimals {
  color: white !important;
}

.order-entry__rt-label {
  color: white !important;
}

.order-entry__input {
  color: #0defdf !important;
  text-align: right !important;
}

/* website template V2  */
.order-entry__label-in-input {
  color: yellow !important;
}

.order-entry__select {
  color: white !important;
}

.advanced-order-form__select {
  color: white !important;
}

.advanced-order-form__input {
  color: #0defdf !important;
  text-align: right !important;
}

.form-control:focus {
  color: #ffffff !important;
}

.form-control {
  color: #ffffff !important;
}

.confirm-order-modal__sold {
  border-radius: 30px !important;
}

.confirm-order-modal__bought {
  border-radius: 30px !important;
}

.snackbar {
  justify-content: center !important;
}

.snackbar__text {
  font-size: 2rem !important;
  align-self: center!important;
}
.snackbar__close {
  margin: 1rem 2rem;
  align-self: center!important;
}

.order-entry__label-in-input {
  font-size: 1.7rem !important;
}

.confirm-order-modal__bought__header {
  justify-content: center !important;
  font-size: 3rem !important;
}

.confirm-order-modal__sold__header {
  justify-content: center !important;
  font-size: 3rem !important;
}

.advanced-order-form__label-in-input {
  font-size: 1.5rem !important;
  color: yellow !important;
}

.advanced-order-form__error {
  font-size: 1.5rem !important;
}

.order-entry__label-in-input {
  position: relative !important;
}

.advanced-order-form__label-in-input {
  position: relative !important;
}

.kyc-settings__header-text {
  font-size: 3rem !important;
}

.kyc-settings__description {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.user-settings-page__title {
  font-size: 3rem !important;
}

.user-settings-page__sub-title {
  font-size: 1.5rem !important;
}

.profile-and-security-widget__header-text {
  line-height: 6rem !important;
}

.trade-report-page__activity-report-header-title {
  font-size: 3rem !important;
}

.trade-report-page__activity-report-header-text {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.api-keys-page__header-title {
  font-size: 3rem !important;
}

.api-keys-page__header-text {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.user-contacts-page__header-text {
  font-size: 3rem !important;
}

.user-contacts-page__text {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.affiliate-program-page__header {
  font-size: 3rem !important;
}

.affiliate-program-page__description {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

/* .asset-activity-details-header__product-icon {
  margin-right: 0rem !important;
} */

.margin-instrument-selector-row__details {
  color: #0defdf !important;
}

.instrument-selector-popover__content {
  width: 300px !important;
}

.instrument-selector-popover__content .flex-table__wide-row-container{
  border: 1px solid #ffffff;
}

.instrument-selector-popup__body {
  width: auto !important;
}

.instrument-selector-popup__column {
  text-align: left !important;
  margin-left: 40px;
  font-family: Arial !important;
  font-size: 1.3rem !important;
}

.ap-button__btn--additive {
  background: var(--success__bg-color);
}

.buy-sell__select-label {
  font-size: 2.5rem !important;
}

.buy-sell__tab--sell-selected {
  border-bottom: 4px solid var(--exchange-colors__sell) !important;
  border-right: 4px solid var(--exchange-colors__sell) !important;
  border-bottom-right-radius: 10px !important;
}

.buy-sell__tab--buy-selected {
  border-bottom: 4px solid var(--exchange-colors__buy) !important;
  border-left: 4px solid var(--exchange-colors__buy) !important;
  border-bottom-left-radius: 10px !important;
}

.custom-snackbar--info {
  background-color: #4283fc !important;
}

.label-in-input {
  position: relative !important;
  color: yellow !important;
}

.instrument-table__row--last-price {
  border: none !important;
}

.instrument-row {
  margin-bottom: 1rem !important;
  margin-top: 1.6rem;
}

.instrument-selector__symbol {
  font-size: 2.1rem !important;
}

.order-entry__rt-label--additive {
  font-size: 1.5rem !important;
}

.order-entry__rt-label--checked-additive {
  /* font-size: 1.9rem !important; */
  border-bottom: 4px solid var(--exchange-colors__buy) !important;
}

.order-entry__rt-label--subtractive {
  font-size: 1.5rem !important;
}

.order-entry__rt-label--checked-subtractive {
  /* font-size: 1.9rem !important; */
  border-bottom: 4px solid var(--exchange-colors__sell) !important;
}

.ap-button__btn {
  font-size: 1.4rem !important;
}

/* .reset-password-form__btn.reset-password-form__btn--general {
background-color: #4283fc !important;
font-size: 1.5rem !important;
padding: 0.5rem 2rem;
height: auto;
} */

.asset-activity-details-header__clear__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
}

.activity-reporting-buttons__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
  width: 100% !important;
}

.api-keys-side-pane-button__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
}

/* .api-keys-page__documentation {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
} */

.cant__login a {
  color: var(--color-secondary) !important;
}

.cant_login_sto a {
  font-size: 1.7rem !important;
}

.login_username {
  font-size: 3rem !important;
}

.buy-options-container .label-in-input {
  position: absolute !important;
}

.kyc-level__change__btn {
  background: var(--warning__bg-color) !important;
}

.ap-modal__header {
  font-size: 2rem !important;
}

.enable-2fa-modal__text-item {
  font-size: 1.5rem !important;
}

.instrument-selector-popup__24h-positive, .instrument-tickers24h-change_positive {
  color: var(--padded-decimal__decimals-buy-color) !important;
}

.instrument-tickers24h-change_negative {
  color: var(--padded-decimal__decimals-sell-color) !important;
}

.trade-settings__box-header {
  font-size: 2rem !important;
}

.trade-settings__box-body p {
  font-size: 1.6rem !important;
  line-height: 20px !important;
}

.enable-interest-confirmation__scrollable p {
  font-size: 1.8rem !important;
}

.isvg.loaded.ap-icon.ap-icon--checkbox.ap-checkbox__icon.ap-checkbox__icon--checkbox {
  top: -5px !important;
  color: #380047 !important;
}

.ap-checkbox__checkbox label:after {
  background-color: white !important;
}

.ap-checkbox__checkbox label {
  font-size: 1.5rem !important;
}

.price-history {
  border-bottom: solid 1px white !important;
}

.volume-history {
  border-bottom: solid 1px white !important;
}

.create-api-key__icon.ap-checkbox__icon.create-api-key__icon--checkbox {
  color: #380047 !important;
  top: -5px !important;
}

.form-text-muted.ap-input__info.send-address__info {
  font-size: 1.4rem !important;
  line-height: normal !important;
}

.ap--label.ap-input__label.reset-password__label {
  /* color: black !important; */
  color: #ffffff !important;
  
}

.ap-button__btn.ap-button__btn--additive.reset-password__btn.reset-password__btn--additive {
  /* color: black !important; */
  color: #ffffff !important;
}

.ap-input__error {
  padding-top: 1.4rem !important;
  font-size: 1.4rem !important;
  line-height: 1;
}

.X_object_login {
  opacity: 0.6;
  position: sticky;
  object-position: 50% 50%;
  height: 0px;
}

.X_object_login img {
  object-position: -21vh 0vh;
  max-width: 200vh;
  max-height: 100vh;
}

.text_on_X_object {
  position: sticky;
}

.isvg.loaded.ap-icon.ap-icon--checkbox.ap-checkbox__icon.report-form__icon.ap-checkbox__icon.report-form__icon--checkbox {
  color: #000 !important;
  top: -7px !important;
}

.settings-menu__container {
  margin-top: 3rem;
}

.loginSto_news {
  /* font-size: 3.5rem !important; */
  color: var(--color-secondary-light) !important;
  /* padding: 100px; */
  line-height: 1.5;
}

.reset-password__header {
  background: none !important;
}

.reset-password__header div {
  color: #ffffff !important;
  font-weight: bold;
}

.reset-password__btn {
  background: transparent linear-gradient(104deg,
  rgba(22, 239, 22, .7333333333333333),
  rgba(216, 255, 22, .7176470588235294)) 0 0 no-repeat padding-box !important;
  border-radius: 43px !important;
}

.reset-password__btn:disabled {
  opacity: 0.3 !important;
}

.settings-enable-two-factor-auth__info p {
  font-size: 1.4rem;
}

.asset-activity-details-header__header {
  align-items: center;
  flex-wrap: wrap;
}

.flex-table__row {
  border-bottom: 1px solid var(--table__row-separator-color);
}

.instrument-selector-popup__row--selected {
  background: #343a48!important;
}

.mobile-menu-more-stroke {
  height: 1px;
  background: #4283fc;
  margin: 0px 10px;
  width: 100%;
}
.login__screen__content {
  z-index: 2;
}

.manual-deposit__input-box {
  display: block!important;
}

.navbar-custom-link {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background 0.3s;
  padding: 0.5rem;
  margin: 0.8rem 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.navbar-custom-link:hover {
  background: #4283fc;
  color: #ffffff;
}

.display-message {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  margin-left: 4rem;
  margin-right: 4rem;
}

.display-message .session-status {
  color: #00FF24;
}

.display-message .session-message {
  margin-top: 6rem;
}

.display-message .start-time-countdown {
  margin-top: 3rem;
  border: 2px solid #607D8B;
  padding: 3rem;
}

.display-message .sto-title {
  color: #00FF24;
  font-size: 3.5rem;
}

.display-message .sto-logo {
  max-width: 250px;
  height: auto;
  margin-bottom: 2rem;
}

.end-time-countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: 2px solid #607D8B;
  padding: 2rem;
  
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.display-message .message-text {
  border-top: 1px solid #ffffff;
  padding: 4rem 0 0 0;
}

.message-text .presale-text {
  margin-top: 7rem;
}

.display-message .message-text, 
.display-message .session-message,
.display-message .session-status {
  
  font-weight: 350;
  font-family: "Josefin Sans", sans-serif;
}

/* Credit Card Mercuryo */
.credit-card-container .bg-col {
  background-image: url('../../pages/img/credit-card-bg.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.credit-card-col {
  padding-left: 0!important;
  background-color: #12141A;
}
.credit-card-col .content-text {
  padding: 2rem 4rem;
}
.credit-card-col h1, h3 {
  text-align: center;
  font-weight: bold!important;
}
.credit-card-col ul{
  color: #787878!important;
}
.info-box {
  border: 1px solid #3A71FF;
  background: #3A71FF30;
  color: #ffffff;
  border-radius: 16px;
  padding: 1.5rem 2rem;
}
.credit-card-info ul li {
  color: #ffffff;
}
#credit_card_form {
  padding: 10rem 5rem;
}
.base-lt__layout-template {
  background-color: var(--color-primary)!important;
}
.kyc-level {
  background-color: var(--color-primary)!important;
}
.flex-table__row--empty, .flex-table__row--empty:hover {
  background-color: var(--color-primary)!important;
}
.affiliate-program-page__container-header {
  border-bottom: solid 3px var(--component__header-separator-color) !important;
}
.ap-tab__menu-item {
  padding: 2.5rem 0!important;
}  
.download-report__action-btn__btn:hover {
  background: var(--color-secondary)!important;
  border-color: var(--color-secondary)!important;
}
.api-keys-page__documentation {
  border-radius: 90px!important;
  border: none!important;
  background: var(--color-secondary);
  width: 100%;
}
.api-keys-side-pane-button__btn--general {
  width: 100%!important;
}
.api-keys-page__documentation a:hover {
  color: #ffffff;
}
.api-keys-side-pane-button__btn {
  margin: 0!important;
}
.affiliate__btn-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.affiliate__btn-container .affiliate__btn {
  width: 100px;
}
.trade-report-page__report-header,
.api-keys-page__table-header,
.affiliate-program-page__container-header {
  border-radius: 1rem 1rem 0 0;
}
.schedule-report-list__container .flex-table__wide-row-container,
.download-report__container .flex-table__wide-row-container {
  border-radius: 0 0 1rem 1rem;
}
.affiliate-program-page__container,
.affiliate-program-page__container {
  border-radius: 1rem!important;
}
.affiliate-program-page__container-header {
  background: transparent!important;
}
.affiliate__tag-description {
  font-size: 1.5rem;
}
.affiliate__tag {
  background: var(--color-primary-light);
  width: fit-content;
  border-radius: 1rem;
  padding: .6rem 1rem;
  font-size: 1.4rem!important;
}
.icon-button:hover {
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}
.product-balances__wrapper::-webkit-scrollbar-track {
  background: #888888;
}
.product-balances__wrapper::-webkit-scrollbar-thumb {
  background: #485a5a;
}

/* Wallets */
.wallet-details {
  border-radius: 1rem 1rem 0 0!important;
}
.wallet-details__detail {
  border-right: none!important;
}
.activity-pagination__text {
  border: none!important;
}
.flex-table__column.activity__header-column,
.transfers__header-column {
  border-right: 1px solid var(--color-primary-light)!important;
}
.activity-pagination__text {
  height: auto!important;
}
.asset-activity-details-header__product-icon {
  width: auto!important;
  height: auto!important;
  margin-right: 1rem!important;
}
.asset-activity-details-header__product-icon img {
  height: 30px;
}
.transfer-request-notification-item {
  border: 2px solid var(--color-primary-hover);
  border-radius: 16px;
  margin-bottom: 1rem;
}
.send-receive__main-form {
  background-color: var(--color-primary)!important;
}
.receive__source, .receive__source-item {
  background: var(--color-primary-light)!important;
}
.receive__source-item--active, .receive__source-item:hover {
  background: var(--color-secondary)!important;
}
.network_button img,
.network_button_selected img {
  width: 10rem;
}
.network_button_selected {
  background-color: var(--color-secondary)!important;
}
.receive-address__qr-code {
  margin-bottom: 2rem!important;
}
.receive-address__copy-address-payload {
  display: flex;
  justify-content: center;
  align-items: center;
}
.receive-address__description, 
.receive-address__copy-address-text {
  font-size: 2rem;
}
.receive-address__description {
  margin-bottom: 1rem!important;
}
.receive-address__address {
  font-size: 1.4rem!important;
  margin-bottom: 0rem!important;
  margin-top: 0!important;
}
.receive-address__copy-icon--copy {
  margin-top: 0!important;
}
.receive-form__input-box .label-in-input,
.send-form__input-box .label-in-input {
  background: transparent;
}
.receive-form__label-text,
.send-form__label-text {
  font-size: 18px;
}
.send-address__form-body p {
  font-size: 14px;
}
.send-form__source, .send-form__source-item {
  background-color: var(--color-primary-light)!important;
}
.send-form__source-item--selected {
  background: var(--color-secondary)!important;
}
.transfers-filters__rt-wrapper {
  background: var(--color-primary-light);
}
.transfers-filters {
  padding: 1rem 1.5rem 1rem 1.5rem!important;
}

/* Trade View */
.instrument-selector__trigger {
  border: none!important;
}
.trading-layout__container .instrument-row {
  background: var(--color-primary);
  border-radius: 16px;
  width: auto;
  padding: 2rem 1rem;
  /* margin: 1rem; */
}
.instrument-selector-popover__popover {
  background: var(--color-primary-light);
  border-radius: 16px;
  border: none!important;
}
/* .instrument-selector-popover__popover:hover, .instrument-selector-popover__popover:active  {
  background: none!important;
} */
.instrument-selector__trigger:hover, .instrument-selector__trigger:focus {
  background: none!important;
}

.order-entry__buy-sell-radio-tab-wrapper {
  padding: 0!important;
}
.order-entry__rt-label.btn {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-entry__rt-wrapper {
  background: var(--color-primary-light)!important;
}
.order-entry__rt-wrapper.btn-group.ap-segmented-button {
  border-radius: 16px;
  padding: 0.6rem;
}
.order-entry__rt-label.ap-segmented-button__label--additive:hover {
  background-color: transparent !important;
  color: var(--exchange-colors__buy)!important;
}
.order-entry__rt-label--checked.ap-segmented-button__label--additive,
.order-entry__rt-label--checked.ap-segmented-button__label--additive:hover {
  background: var(--exchange-colors__buy)!important;
  border: none!important;
  border-radius: 1rem!important;
  padding: 0.5rem!important;
  color: #ffffff!important;
}
.order-entry__rt-label.ap-segmented-button__label--subtractive:hover {
  background-color: transparent !important;
  color: var(--exchange-colors__sell)!important;
}
.order-entry__rt-label--checked.ap-segmented-button__label--subtractive,
.order-entry__rt-label--checked.ap-segmented-button__label--subtractive:hover {
  background: var(--exchange-colors__sell)!important;
  border: none!important;
  border-radius: 1rem!important;
  padding: 0.5rem!important;
  color: #ffffff!important;
}
.order-entry__rt-label.ap-radio-tab__label:hover,
.ap-radio-tab__label--checked-additive{
  background: transparent!important;
}
.order-entry__form {
  height: auto!important;
}
.order-entry__label-in-input,
.advanced-order-form__label-in-input {
  background: transparent!important;
}
.slide-pane__header,
.advanced-order-sidepane__tab-container {
  background: var(--color-primary-light)!important;
}
.verification-required {
  background: #0866f76b!important;
  border: 1px solid #0866f7;
  border-radius: 1rem;
  color: #ffffff;
}
.verification-required:hover{
  background: #0866f7ab!important;
}
.kyc-level__change__btn:hover {
  border: 1px solid var(--color-secondary)!important;
}
.report-form__form-body {
  /* background: #12141a;
  border: 1px solid var(--color-primary-light); */
}

.standalone-form__close-icon {
  display: none;
}

/* Asset Activity Details */
.asset-activity-details-header__container {
  height: auto !important;
  margin: 1rem 0 !important;
}
.asset-activity-details-header__header {
  align-items: center !important;
  justify-content: start !important;
  flex-direction: row !important;
}
.asset-activity-details-header__left-container,
.asset-activity-details-header__right-container {
  margin: 1rem !important;
}

.asset-activity-details-header__selector-container--product {
  margin-left: 1rem !important;
}

.asset-activity-details-header__clear__btn--general {
  margin-left: auto !important;
  margin-right: 1rem !important;
}

.ap-tab__menu-item.order-history__menu-item {
  padding: 2.5rem !important;
}

/* Transfer Request */
.transfer-request-notification-item__btn-text {
  font-size: 1.4rem;
}
.transfer-request-notification-item__buttons {
  justify-content: end;
}

/* Fiat */
.fiat-sidepane__main-form,
.fiat-withdraw-form__label-in-input {
  background: var(--color-primary) !important;
}

/* Profile Username */
.profile-username-info {
  padding: 0 2rem;
  position: relative;
  top: -20px;
  margin-left: auto;
  color: #aeaeae;
  font-size: 1.3rem;
}

/* System Notice */

.system-notice {
  margin: 1rem 0;
  padding: 2rem;
  background: #c6535340;
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid #ff3a3a;
  width: 75%;
}
.system-notice h3 {
  text-align: left;
  margin-bottom: 1rem;
}
.system-notice p {
  font-size: 1.5rem;
}
.system-notice-mobile {
  display: none;
}

/* AP Checkbox */
label[for="tc_checkbox"] {
  text-wrap: wrap!important;
}
.ap-checkbox__checkbox-error {
  color: var(--danger__color);
    padding-top: 0rem !important;
    font-weight: bold;
    display: block;
    font-size: 1.4rem !important;
    line-height: 1;
}

.reset-password__input-wrapper {
  padding-bottom: 0!important;
  text-align: start!important;
}

/* Create Report Side pane */
.report-form__select-label {
  margin: 0!important;
}
.confirm-single-report__lwt-container {
  padding-top: 1rem;
  height: auto!important;
}
.confirm-single-report__lwt-label {
  width: auto!important;
}

/* API Keys */
.retail-api-key-list__icon {
  width: 3.6rem!important;
}
.retail-api-key-list__check, 
.retail-api-key-list__text-item {
  font-size: 1.4rem!important;
}
.retail-api-key-list__check {
  color: #1ac95a !important;
  font-weight: bold;
}
.api-key-list__column:first-child {
  justify-content: start;
}
.api-key-list__column {
  justify-content: center;
}
.retail-api-key-list__btn {
  background: var(--button-inline__subtractive-color--hover) !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 20px !important;
}
.retail-api-key-list__image {
  color: #ffffff!important;
  height: 1.6rem!important;
}
.retail-api-key-list__row {
  height: auto!important;
}

/* Transfer Request */
.transfer-request-notification-item__btn-icon {
  display: none!important;
}
.transfers__action-btn-icon {
  display: none!important;
}

/* Order History */
.order-history-table__row {
  height: 2.8rem!important;
}

.confirm-order-modal__bought__header,
.confirm-order-modal__bought__content,
.confirm-order-modal__bought__footer,
.confirm-order-modal__lwt-container {
  background: var(--modal__bg-color)!important;
}
.confirm-order-modal__bought__btn {
  width: 90%;
  margin: auto !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.order-history-table__row .ap-inline-btn__btn--subtractive {
  background: var(--danger__color)!important;
  border: none;
  border-radius: 8px!important;
  padding: .45rem 1rem!important;
}
.order-history-table__image--cancel {
  margin-right: 0.8rem!important;
  height: auto!important;
  color: #ffffff!important;
}
.order-history-table__text {
  color: #ffffff!important;
}

.ap-modal__close-icon {
  top: 10px!important;
  right: 10px!important;
}

.base-product-price-unit {
  font-size: 1.4rem;
}

@media only screen and (max-width: 500px) {
  .credit-card-col .content-text {
    padding: 0rem 4rem;
  }
  #credit_card_form {
    padding: 2rem 2rem 5rem 2rem;
  }
  .credit-card-container #credit_card_vector {
    width: 60%;
    padding: 50px 0;
  }
  .mercuryo-credit-card-page .eotc-buy-sell-layout__col {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
  }
  .mercuryo-credit-card-page .eotc-buy-sell-layout__footer {
    margin-top: 0px!important;
  }
  .kyc-settings {
    padding: 0!important;
  }
  .kyc-settings__levels {
    display: flex!important;
    justify-content: center;
    align-items: center;
  }
  .kyc-level, .kyc-level--selected {
    margin-right: 0!important;
  }
  .order-history-table {
    width: 180%!important;
  }
  .ap-tab__tab.order-history__tab {
    margin-left: 2rem!important;
    margin-right: 2rem!important;
  }
  .asset-activity-details-layout {
    margin-bottom: 5rem;
  }
  .download-report__column--created {
    min-width: auto!important
  }
  .transfer-request-notification-item {
    padding: 1.5rem 2.5rem;
  }
  .transfer-request-notification-item__buttons {
    margin-top: 1.2rem;
  }
  .dashboard-page__notification-row {
    margin: 0!important;
  }
  .network_button img,
  .network_button_selected img {
    width: 5rem;
  }
  .receive-address__address {
    font-size: 1.2rem !important;
  }
  .receive-address__qr-code {
    margin-bottom: 1rem !important;
  }
  .receive-address__copy-address-text {
    margin-bottom: 0 !important;
  }
  .send-form__source-item {
    padding: 1rem 2rem!important;
  }
  .send-receive-confirm-modal__lwt-text {
    display: block;
    max-width: 155px;
    word-wrap: break-word;
  }
  
  /* Trade View */
  .trading-layout__container {
    margin: 0!important;
  }
  .trading-layout__container .instrument-row {
    border-radius: 0;
  }
  .instrument-selector-popover__content {
    width: 100% !important;
  }

  
  .transfer-request-notification-item__btn {
    width: 100%!important;
  }
  .transfer-request-notification-item__btn-text {
    display: block!important;
  }
  .transfer-request-notification-item__btn-icon {
    display: none!important;
  }
  .flex-table__row.instrument-selector-popup__row {
    height: 6rem!important;
  }

  .asset-activity-details-header__clear__btn--general {
    margin-left: 1rem!important;
  }

  /* Fiat */
  .fiat-sidepane-header__title {
    font-size: 1.8rem!important;
    padding-top: 0.5rem!important;
  }
  .fiat-sidepane-header__logo {
    height: 2.8rem;
    margin-right: 1.6rem;
  }
  .fiat-sidepane-header {
    margin-top: 2.6rem;
    margin-left: 2.2rem;
    width: calc(100% - 2.2rem);
  }
  .fiat-sidepane__main {
    overflow: auto!important;
  }

  /* System notice */
  .system-notice-mobile {
    display: block;
  }
  .system-notice {
    width: 100%;
  }
  .system-notice p {
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-align: left;
  }

  .transfers__action-btn-icon {
    width: 2rem !important;
    display: block!important;
  }

  .activity__type-icon {
    width: 25px !important;
  }
}