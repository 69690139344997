.standalone-form__input-wrapper {
  margin-bottom: 2.5rem;
}

.standalone-form__input-box {
  height: 3rem;
}

.standalone-form__close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--font__color-primary);
}

.standalone-form__container {
  margin: auto;
  width: var(--standalone-form__container-width);
  min-height: min-content;
  display: flex;
  flex-direction: column;
  background: var(--standalone-form__container-bg-color);
  box-shadow: var(--component__box-shadow);
}

.standalone-form__container.reset-password__container {
  
  margin: auto;
  width: var(--standalone-form__container-width);
  min-height: min-content;
  display: flex;
  flex-direction: column;
  background: #18191dcc!important;
  border-radius: 17px;
  border: 1px solid #333;
  opacity: 1;

}

.standalone-form__header {
  position: relative;
  height: 6.4rem;
  display: flex;
  align-items: center;
  color: var(--standalone-form__header-color);
  background: var(--standalone-form__header-bg-color);
  padding-left: 2.8rem;
  box-shadow: var(--standalone-form__header-box-shadow);
}

.standalone-form__message-text,
.standalone-form__header-text {
  font-size: 1.6rem;
  color: var(--font__color-primary);
}

.standalone-form__message {
  height: 14rem;
  display: flex;
  align-items: center;
  color: var(--standalone-form__message-color);
  background: none;
  padding: 0 2.8rem;
  box-shadow: var(--standalone-form__message-box-shadow);
}

.standalone-form__message-footer {
  height: 6.2rem;
  margin: 1rem 2.8rem !important;
  display: flex;
  align-items: center;
  border-top: solid 1px var(--border__color);
}

.standalone-form__message-footer .standalone-form__btn--subtractive,
.standalone-form__message-footer .standalone-form__btn--additive {
  font-size: 1.1rem;
  display: inline-block;
  height: 3.2rem;
  line-height: 3.2rem;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  padding: 0 1rem;
  border: 1px solid transparent;
  color: var(--font__color-primary);
  white-space: nowrap;
  width: auto;
  min-width: var(--standalone-form__btn-width);
}

.standalone-form__message-footer .standalone-form__btn--subtractive {
  background: var(--danger__bg-color);
}

.standalone-form__message-footer .standalone-form__btn--additive {
  background: var(--success__bg-color);
}

.standalone-form__message-footer .standalone-form__btn--subtractive:hover {
  background: var(--danger__bg-color--hover);
}

.standalone-form__message-footer .standalone-form__btn--additive:hover {
  background: var(--success__bg-color--hover);
}

.standalone-form__message-footer .standalone-form__btn--subtractive:focus {
  background: var(--danger__bg-color--focus);
  border: solid 1px var(--danger__border-color--focus);
}

.standalone-form__message-footer .standalone-form__btn--additive:focus {
  background: var(--success__bg-color--focus);
  border: solid 1px var(--success__border-color--focus);
}

.standalone-form__success {
  padding: 1rem;
}

.standalone-form__terms-text {
  margin: 1rem 0;
  line-height: 1.45;
  color: var(--font__color-secondary-light);
}

.standalone-form__information-text {
  margin-bottom: 2.8rem;
  font-size: 1.4rem;
  color: var(--font__color-secondary-light);
}

.standalone-form__password-strength {
  color: var(--information__color);
}

.standalone-form__password-error {
  color: var(--danger__bg-color);
  margin-bottom: 2.5rem;
}

.standalone-form__footer {
  height: 6.2rem;
  margin: 0 2.8rem;
  display: flex;
  align-items: center;
  border-top: solid 1px var(--border__color);
}

.standalone-form__footer-separator {
  width: 0;
  height: 2rem;
  border-right: solid 1px var(--border__color);
  margin: 0 1.5rem;
}

.standalone-form__form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.75rem;
  background: #18191dcc;
  border-radius: 17px;
  border: 1px solid #333;
  opacity: 1;
  padding: 50px 40px 30px 40px!important;
}

.standalone-form__btn {
  font-size: 1.4rem !important;
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  padding: 0 1rem;
  border: 1px solid transparent;
  color: var(--font__color-primary);
  white-space: nowrap;
  background: var(--color-secondary) !important;
  border-radius: 90px;
  height: auto;
  line-height: normal;
  position: relative;
  overflow: hidden;
  width: 100% !important;
}

.standalone-form__separator {
  margin: 0 0 2.5rem 0;
  display: block;
  border-top: solid 1px var(--border__color);
}

.standalone-form__auto-tab-input {
  margin-bottom: 2.5rem;
}

.standalone-form__radio-list {
  margin-bottom: 2.5rem;
}

.standalone-form__error {
  color: var(--danger__color);
  padding-top: 0.5rem;
  display: inline-block;
}

.standalone-form__info {
  padding-top: 0.5rem;
}

.standalone-form__form .reset-password__info {
  padding-top: 1.4rem !important;
}
.standalone-form__form .reset-password__info span {
  font-size: 1.4rem;
  color: #FFEB3B;
  font-weight: bold;
}
.standalone-form__form .reset-password__error {
  font-weight: bold;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media (max-width: 576px) {
  .standalone-form__wrapper {
    justify-content: flex-start;
  }
  .standalone-form__container {
    width: auto;
    box-shadow: initial;
    margin: 2rem 2rem 5rem 2rem;
  }
  .standalone-form__logo {
    align-self: flex-start;
    position: initial;
    top: initial;
    left: initial;
  }
}
