.trading-layout__container {
    margin: 1.5rem;
  }
  
  .trading-layout__chart-header {
    height: var(--component__header-height);
    line-height: var(--component__header-height);
    background: var(--component__header-bg-color);
    padding: 0 1.75rem;
    border-bottom: 1px solid var(--component__header-separator-color);
  }
  
  .trading-layout__chart-container {
    grid-area: chart;
    margin-left: 0.2rem;
    border: 5px !important;
  }

  .fill-1djIbBXv {
    background-color: black;
  }
  
  .trading-layout__tabs-container--orderbook-container {
    grid-area: orderbook;
  }

  
  .trading-layout__activity-container {
    grid-area: oldActivity !important;
  }
  
  .order-history__tab {
    height: auto;
    background: none!important;
    margin: 0 !important;
  }

  .ap-tab__tab.order-history__tab {
    grid-area: newActivity !important;
    margin-bottom: 1rem !important;
    border: none !important;
    border-radius: 100px !important;
    margin: 0 !important;
  }
  /* .ap-tab__menu.order-history__menu {
    border: none !important;
    padding: 1rem;
  }   */
  .order-entry__container {
    grid-area: orderentry;
    /* margin-right: 1rem; */
    border: none !important;
    border-radius: 10px;
    margin: 0!important;
  }

  .order-entry__order-type-wrapper {
    padding-bottom: 20px !important;
  }

  .order-entry__container .order-entry__sb-container, .order-entry__input-wrapper, .order-entry__select-wrapper {
    padding: 0rem 1.6rem !important;
 }

  .ap-tab__menu-item {
    font-weight: normal !important;
    font-size: 1.4rem !important;
  }
  .ap-radio-tab__label {
    font-size: 1.2rem;
    font-weight: bold !important;
  }

  .trading-layout__info-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--trading-layout__info-bar-bg-color);
    height: var(--trading-layout__info-bar-height);
  }
  
  .trading-layout__body {
    /* border-top: 1rem solid black !important; */
    height: var(--trading-layout__body-height);
    height: 100%;
    margin-bottom: 1rem;
    
    display: grid;
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: auto 27rem;
    grid-gap: 1rem;
    grid-template-areas: 
      "chart orderbook orderentry"
      "newActivity newActivity orderentry" !important;
  }

  .order-history__menu {
    border-radius: 0!important;
  }
  @media only screen and (max-width: 968px) {
    .trading-layout__body {
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .order-history-table__body {
      min-width: unset;
    }
    .trading-layout__body {
      width: 100%;
      max-width: 100%;
      grid-template-columns: 35rem minmax(0, 1fr) 35rem !important;
      grid-template-rows: auto 27rem;
      grid-template-areas: 
       "orderbook chart orderentry"
       "newActivity newActivity orderentry" !important;
  /*        "chart orderbook recenttrades orderentry" 
            "activity activity activity orderentry"; */
      }
  }
  
  
  .trading-layout__tabs-container {
    border-left: var(--trading-layout__border);
  }
  
  .trading-layout__chart-tabs-container {
    height: 100%;
  }
  @media only screen and (max-width: 968px) {
    .trading-layout__chart-tabs-container {
      height: calc(100% - 270px);
    }
  }
  
  .trading-layout__tab,
  .trading-layout__tab-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .trading-layout__row {
    display: flex;
    min-height: fit-content;
  }

  .order-entry__select-wrapper {
    margin-top: 2rem;
  }
  
  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 968px) {
    .trading-layout__container {
      overflow-y: visible;
      height: 179rem;
      min-height: 179rem;
    }
  
    .trading-layout__chart-container {
      height: 69rem;
    }
  
    .trading-layout__menu {
      min-height: 3.6rem;
    }
  }
  
  @media only screen and (max-width: 468px) {
    .trading-layout__container {
      height: max-content;
      min-height: max-content;
    }
  
    .trading-layout__body {
      height: max-content;
    }
  
    .trading-layout__row {
      flex-direction: column;
    }
    
    .order-entry__container {
      margin: 1rem;
    }
    .order-entry__body {
      padding: 0!important;
    }
  }

  .kyc-msg {
    background-color: #6eafff;
    color: #ffffff;
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .kyc-msg a{
    font-size: 1.5rem;
  }

  .kyc-link {
    padding-left: 10px;
  }

  .kyc-link-url {
    text-decoration: underline!important;
    color: #9cfa16!important;
  }