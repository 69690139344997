.title-container {
  width: 100%;
  display:flex;
  flex-direction:row;
}

.col-left {
  width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.col-left h2 {
  font-size: 2.4rem;
  padding-left: 2.5rem;
}

.grid-container {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 50px;
  min-height: 420px;
  width: 100%;
}

.grid-item {
  width: 32%;
  margin: auto;
  padding: 50px 10px 50px 10px;
  border: 1px solid #454545;
  border-radius: 5px;
}

.grid-content {
  text-align: center;
  min-height: 50px;
}

.card-icon {
  margin: auto;
  margin-bottom: 10px;
  font-size: 5rem;
  display: block;
}

.cp-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--secondary__bg-color);
  border-radius: 10px;
  min-height: 60rem;
  padding: 10rem;
}


div #mobilum-container {
  width: 100%;
  min-height: 50rem;
  margin: auto;
}

.col-right {
  width: 30%;
}

.card-img {
  position: relative;
  width: 100%;
  padding-right: 2.5rem;
}

.eotc-buy-sell-layout__col {
  margin-top: 50px;
}

.eotc-buy-sell-layout__col--lg {
  width: 100%;
}

.eotc-buy-sell-layout__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.eotc-buy-sell-layout__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.eotc-buy-sell-layout__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

@media only screen and (max-width: 720px) {
  .grid-item {
    width: 100%;
  }
}
