.login-form__form {
  margin: 3rem 2.8rem 2.8rem;
}
.login-form__container {
  border-radius: var(--standalone-form__border-radius);
}

.login-form__header {
  height: 6.8rem;
  border-top-left-radius: var(--standalone-form__border-radius);
  border-top-right-radius: var(--standalone-form__border-radius);
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.login-form__header-text {
  font-size: 2rem;
}

.login-form__footer {
  padding: 0 2.8rem;
  margin: 0;
}

.login-form__label {
  font-size: 1.4rem;
  letter-spacing: -0.5px;
}

.login-form__input {
  height: 3.6rem;
  border-radius: 5px;
  border: 1px solid var(--border__color);
}

.login-form__input-wrapper {
  padding-bottom: 3.6rem;
  margin: 0;
}

.login-form__btn {
  height: 5rem;
  border-radius: 5px;
  font-size: 1.4rem;
}

.login-form__separator {
  margin: 0.9rem -2.8rem 3rem -2.8rem;
}

.login-form__link {
  font-size: 1.2rem;
}

.login-page {
  height: 100vh;
}
.login-page .col-brand {
  height: 100vh;
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-page .col-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(../../pages/img/bg1-01.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-logo {
  width: 55%;
}
.login-form-container {
  width: 70%;
  margin: auto;
}
.login-fields__input-wrapper {
  display: flex;
  flex-direction: column;
}
.login-form-container label{
  text-align: left;
  margin-bottom: 0.5rem;
}
.login-form-container .ap-input__error {
  padding-top: 0.5rem !important;
  font-weight: bold;
}
.login-form-container .form-group {
  margin-bottom: 30px !important;
}
.login-form-container.signup-form .form-group {
  margin-bottom: 20px !important;
}
.login-signup-toggle {
  padding: 0.8rem;
  background: #33333380;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.login-signup-toggle a {
  padding: 1.5rem;
  color: #ffffff;
  width: 50%;
  font-size: 2rem;
}
.login-signup-toggle a.active {
  background: var(--color-secondary);
  border-radius: 16px;
  color: #ffffff;
}
.login-btn {
  font-size: 2.25rem !important;
  padding: .8rem !important;
  width: 100%;
  border-radius: 16px;
}
.visit-website-link {
  margin: 1rem;
  color: var(--color-secondary);
}
.visit-website-link:hover {
  color: var(--color-secondary-light);
}
.signup-form__container .form-group {
  display: flex;
  flex-direction: column;
}
.mobile-logo {
  display: none;
} 
@media screen and (max-width: 500px) {
  .login-page .col-brand {
    display: none;
  }
  .login-form-container {
    width: auto;
    margin: 0 2rem;
  }
  .mobile-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .signup-form .login__form__main {
    padding: 30px 35px;
  }
  .signup-form {
    height: auto;
  }
  .signup-form .col-form {
    height: auto;
  }
}

@media screen and (min-width: 501px) and (max-width: 992px) {
  .login-page .col-brand {
    display: none;
  }
  .mobile-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-form-container {
    width: 70%;
    margin: 5rem auto;
  }
}
.mobile-custom-title {
  display: none;
}
@media screen and (max-width: 992px) {
  .mobile-custom-title {
    display: block;
  }
}