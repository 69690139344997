.receive-address {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.4rem;
  text-align: center;
}

.receive-address__description {
  margin-bottom: 4rem;
  color: var(--font__color-secondary);
  font-size: 1.4rem;
}

.receive-address__qr-code {
  margin-bottom: 6rem;
}

.receive-address__qr-code canvas{
  max-height: 80vh;
  max-width: 80vh;
}

.receive-address__copy-address-text {
  margin-bottom: 1.1rem;
  color: var(--font__color-secondary);
}

.receive-address__destination-tag-text {
  margin-top: 2rem;
  color: var(--font__color-secondary);
  font-size: 14px!important;
}
.receive-address__address.destination-tag {
  font-size: 20px!important;
  font-weight: bold;
}
.receive-address__address {
  color: var(--font__color-primary);
  display: inline-block;
  margin-right: 1rem;
  margin-top:1rem;
  font-size: 1.6rem;
  max-width: 35rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.receive-address__spinner-container {
  position: relative;
}

.receive-address {
  font-size: 1.6rem;
  color: var(--font__color-secondary);
}

.receive-address__copy-address-payload{
  width: 100%;
}

.receive-address__copy-icon--copy{
  cursor: pointer;
  margin-top:1rem;
}


/* MEDIA QUERIES */

/* MEDIA QUERIES */

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .receive-address__copy-address-payload{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .receive-address__address {
    margin-right: unset;
    font-size: 1.3rem;
  }

  .receive-address__copy-address-text{
    font-size: 1.2rem;
  }

  .receive-address__description{
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .receive-address__qr-code {
    margin-bottom: 3.6rem;
  }
}