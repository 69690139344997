.tv-chart {
  height: 100%;
}

.tv-chart--with-depth-chart {
  min-height: 250px;
  flex-shrink: 0;
  /* @NB: 200px = hard-coded depth chart height */
  height: calc(100% - var(--component__header-height) - 200px);
}

.tv-chart__chart-container {
  height: 100%;
}

.tv-chart__text {
  text-align: center;
  line-height: 40rem;
}

.tv-chart__container {
  width: 100%;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1280px) {
  .tv-chart {
    height: 100%;
  }
  
  .tv-chart--with-depth-chart {
    /* @NB: 200px = hard-coded depth chart height */
    height: calc(100% - var(--component__header-height) - 200px);
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .tv-chart--with-depth-chart{
    background: var(--component__bg-color);
  }
  
  .tv-chart__chart-container{
    padding: 0 2rem;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 500px) {
  .tv-chart__chart-container{
    padding: 0;
  }
}